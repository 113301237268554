.t_Content_custom {
  color: #FFF;
  background-color: #3a6a13;
  font-size: 14px;
  line-height: 1.7em;
  padding: 12px 15px;
  padding: 6px 8px \9;
  font-weight: bold;
}

.t_Content_custom strong {
  font-weight: normal;
  font-size: 12px;
}

.t_Content_custom span {
  font-weight: normal;
  font-size: 10px;
}

.t_Content_custom a,
.t_Content_custom a:hover {
  color: #000;
  text-decoration: underline;
  background: none;
  border: 0;
}

.t_Content_custom a:hover {
  color: #f0481e;
  text-decoration: underline;
}

.t_Content_custom a:active {
  color: #999;
  text-decoration: none;
}
