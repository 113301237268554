.c-secHeading {
    text-align: center;
    color: rgba($GRN, 1);

    &__Title {
        @include font-size(28);

        line-height: 1.4;
        margin: 0 0 8px;
        @extend %f-jp_b;
        color: rgba($GRY, 1);

        &:before {
            position: absolute;
            border-radius: 24px;
            top: -50px;
            content: "";
            height: 36px;
            padding: 7px;

            // モバイル
            @include mq(mobile, desktop) {
                top: -40px;
            }
        }

        &.is-newEmployee {
            position: relative;
            margin-top: 30px;

            &:before {
                left: calc(50% - 80px);
                width: 160px;
                background: rgba($GRN, 1) url("../img/label-newEmployee.png") center top 7px no-repeat / auto 21px;
            }
        }

        &.is-ojt {
            position: relative;
            margin-top: 30px;

            &:before {
                left: calc(50% - 100px);
                width: 200px;
                background: rgba($GRN, 1) url("../img/label-ojt.png") center top 7px no-repeat / auto 21px;
            }
        }

        &.is-ojtFeature {
            position: relative;
            margin-top: 0px;

            &:before {
                left: calc(50% - 62px);
                top: -40px;
                width: 124px;
                height: 28px;
                padding: 4px;
                border-radius: 24px;
                background: rgba($GRN, 1) url("../img/label-ojt.png") center top 7px no-repeat / auto 14px;
            }
        }

        // モバイル
        @include mq(mobile, desktop) {
            @include font-size(20);

            margin: 0 0 12px;
        }

    }

    &__Sub {
        @include font-size(16);
        line-height: 1;
        @extend %f-en_b;
        color: rgba($GRY_LIGHT, 1);
        margin: 0;

        // モバイル
        @include mq(mobile, desktop) {
            @include font-size(14);
        }
    }

    &__Desc {
        @include font-size(16);
        line-height: 2;
        @extend %f-en_b;
        color: rgba($GRY, 1);
        margin-top: 24px;

        // モバイル
        @include mq(mobile, desktop) {
            @include font-size(14);
            padding: 0 20px;
        }
    }

    &.white {
        color: rgba($WHT, 1);
    }
}


