%blurbox,
.blurbox {
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.08);
}

%blurbox_inset {
  box-shadow: inset 0 0 10px 0 rgba(0,0,0,0.15);
}

%blurbox_inset-right {
  box-shadow: inset -3px 0 3px 0 rgba(0,0,0,.15);
}
// box-shadow: inset horizonal vertical blur-radius spread color;

%blurbox_down {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

%blurbox30 {
  box-shadow: 0 0 10px 10px rgba(0,0,0,0.05);
}

%blurbox0,
.blurbox0 {
  box-shadow: 0 0 0 0 rgba(0,0,0,0);
}

%blurbtn,
.blurbtn {
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.15);
}
