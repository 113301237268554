.p-term {
    margin-bottom: 120px;

    &-title {
        @include font-size(36);
        padding: 80px 0 24px;
        color: $GRN;
        border-bottom: 1px solid rgba($BLK,.5);
        margin-bottom: 48px;

        @include mq($from: mobile,$until: desktop) {
            @include font-size(30);
            padding: 36px 0 12px;
            margin-bottom: 24px;
        }
    }

    &__block {
        padding-bottom: 24px;
        margin-bottom: 0;
        @include font-size(16);
        line-height: 2;
        color: $BLK;

        @include mq($from: mobile,$until: desktop) {
          padding-bottom: 12px;
          margin-bottom: 24px;
          @include font-size(14);
        }

        &-title {
            padding-bottom: 24px;

            @include mq($from: mobile,$until: desktop) {
              padding-bottom: 12px;
            }
        }

        &-signature {
            text-align: right;
            font-weight: bold;
        }

        &-textBody {
            padding-left: 50px;

            p {
                margin-bottom: 12px;

                @include mq($from: mobile,$until: desktop) {
                  margin-bottom: 6px;
                }
            }

            ul {
                padding-left: 50px;

                li {
                    font-weight: bold;
                    margin-bottom: 12px;
                    list-style-type: decimal;

                    @include mq($from: mobile,$until: desktop) {
                      margin-bottom: 6px;
                    }
                }

                @include mq($from: mobile,$until: desktop) {
                    padding-left: 0px;
                }
            }
            @include mq($from: mobile,$until: desktop) {
                padding-left: 0px;
            }

        }
    }

    &__copy{
      text-align: center;
      @include font-size(14);
      margin-bottom: 80px;
      a{
        color: $GRN;
        &:hover{
          text-decoration: underline;
          color: $HOV;
        }
      }
    }
}
