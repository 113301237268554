.page-top {
    .l-qualityItem {
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: block;
        overflow: hidden;
        position: relative;
        width: 33.333%;

        @include mq(mobile, desktop) {
            width: 100%;
        }

        // モバイル・ランドスケープ
        @include mq($until: tablet, $and: '(orientation: landscape)') {
            width: 100%;
        }

        // タブレット
        @include mq(tablet, desktop) {
            width: 33.333%;
        }

        &__Innr {
            height: auto;
            padding: 35px 0 30px 30px;

            @include mq(mobile, desktop) {
                padding: 20px 0 20px 20px;
            }

            // モバイル・ランドスケープ
            @include mq($until: tablet, $and: '(orientation: landscape)') {
                padding: 20px 0 20px 20px;
            }

            // タブレット
            @include mq(tablet, desktop) {
                padding: 20px 0 20px 20px;
            }
        }

        &__Sub {
            margin: 0;

            img {
                width: auto;
                height: 20px;

                @include mq(mobile, desktop) {
                    height: 14px;
                }

                // モバイル・ランドスケープ
                @include mq($until: tablet, $and: '(orientation: landscape)') {
                    height: 16px;
                }

                // タブレット
                @include mq(tablet, desktop) {
                    height: 18px;
                }
            }
        }

        &__Lead {
            margin: 120px 0 15px;

            @include mq(mobile, desktop) {
                margin: 30px 0 10px;
            }

            // モバイル・ランドスケープ
            @include mq($until: tablet, $and: '(orientation: landscape)') {
                margin: 45px 0 10px;
            }

            // タブレット
            @include mq(tablet, desktop) {
                margin: 60px 0 15px;
            }

            img {
                width: 180px;
                height: auto;

                @include mq(mobile, desktop) {
                    width: 120px;
                }

                // モバイル・ランドスケープ
                @include mq($until: tablet, $and: '(orientation: landscape)') {
                    width: 148px;
                }

                // タブレット
                @include mq(tablet, desktop) {
                    width: 164px;
                }
            }

            &-exp {
                font-weight: bold;
                @include font-size(16);
                color: rgba($WHT, 1);

                // モバイル
                @include mq(mobile, desktop) {
                    @include font-size(12);
                }

                // モバイル・ランドスケープ
                @include mq($until: tablet, $and: '(orientation: landscape)') {
                    @include font-size(12);
                }

                // タブレット
                @include mq(tablet, desktop) {
                    @include font-size(11);
                }
            }
        }

        &.l-qualityNo_1 {
            background-image: linear-gradient(135deg, rgba(14, 173, 169, 1) 0%, rgba(110, 230, 196, 1) 100%);
        }

        &.l-qualityNo_2 {
            background-image: linear-gradient(45deg, rgba(110, 230, 196, 1) 0%, rgba(254, 219, 142, 1) 100%);
        }

        &.l-qualityNo_3 {
            background-image: linear-gradient(135deg, rgba(254, 219, 142, 1) 0%, rgba(253, 143, 135, 1) 100%);
        }

        &::before {
            content: "";
            z-index: 2;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-style: solid;
            border-width: 360px 0 0 454px;
            border-color: transparent transparent transparent rgba($WHT, .1);

            @include mq(mobile, desktop) {
                border-width: 190px 0 0 324px;
            }

            // モバイル・ランドスケープ
            @include mq($until: tablet, $and: '(orientation: landscape)') {
                border-width: 210px 0 0 564px;
            }

            // タブレット
            @include mq(tablet, desktop) {
                border-width: 250px 0 0 254px;
            }
        }
    }
}
