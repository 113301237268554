::-webkit-scrollbar {
    display: none;
}

.vertical-dot-nav {
	position: fixed;
    list-style-type:none;
    background-color: rgba($GRN,.5);
    padding: 0 10px 10px 10px;
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 0px;
    z-index: 10;
}

.vertical-dot-nav .dot {
    background-color: $GRN;
    border-width: 2px;
    border-style: solid;
    padding:2px;
    margin-top:10px;
    margin-left:10px;
    cursor:pointer;
}
