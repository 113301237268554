.page-ojt {
    .l-about {
        text-align: center;

        // モバイル
        @include mq(mobile, desktop) {}

        &__Innr {
            &-item {
                padding: 40px;

                // モバイル
                @include mq(mobile, desktop) {
                    padding: 40px 0px 0px 0px;
                }

                &:nth-child(odd) {
                    border-bottom: 2px solid rgba($GRY_LIGHTEST, 1);

                    // モバイル
                    @include mq(mobile, desktop) {
                        padding-bottom: 20px;
                    }

                    .l-aboutGraph {
                        .l-aboutGraph__text {
                            order: 1;
                            padding: 0 40px 0 0px;
                        }

                        .l-aboutGraph__body {
                            order: 2;
                        }
                    }
                }

                &:nth-child(even) {
                    margin-top: -2px;
                    background: url("../img/icon-bottom.png") no-repeat center top / 41px 15px;
                    border-top: 2px solid rgba($GRY_LIGHTEST, 1);

                    // モバイル
                    @include mq(mobile, desktop) {
                        padding-bottom: 20px;
                    }

                    .l-aboutGraph {
                        .l-aboutGraph__text {
                            order: 2;
                            padding-left: 20px;

                            // モバイル
                            @include mq(mobile, desktop) {
                                padding-left: 0px;
                            }
                        }

                        .l-aboutGraph__body {
                            order: 1;
                        }
                    }
                }
            }
        }
    }
}
