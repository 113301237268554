.page-newEmployee {
    .l-quality {
        margin-top: 5px;
        padding: 0;

        // モバイル
        @include mq(mobile, desktop) {
            margin-top: 2px;
        }

        &__Innr {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: center;
            position: relative;

            &:before {
                position: absolute;
                left: 50%;
                top: 50%;
                margin-left: -20px;
                margin-top: -20px;
                @include font-size(40);
                color: $WHT;
                z-index: 3;

                // モバイル
                @include mq(mobile, desktop) {
                    margin-left: -12px;
                    margin-top: -12px;
                    @include font-size(24);
                }
            }
        }
    }
}
