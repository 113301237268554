.page-function {
    .l-hero {
        padding-top: 72px;

        // モバイル
        @include mq(mobile, desktop) {
            padding-top: 48px;
        }

        &__Innr {
            width: 640px;
            margin: 0 auto;

            // モバイル
            @include mq(mobile, desktop) {
                width: 100%;
                padding: 0 20px;
            }

            &-lead {
                position: relative;

                // モバイル
                @include mq(mobile, desktop) {
                    padding: 0 20px;
                }

                h3 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    width: 100%;

                    img {

                        width: auto;
                        height: height("function-HeroLead.png", 2);

                        // モバイル
                        @include mq(mobile, desktop) {
                            width: auto;
                            height: height("function-HeroLead.png", 3);
                        }

                        // モバイル・ランドスケープ
                        @include mq($until: tablet, $and: "(orientation: landscape)") {
                            width: auto;
                            height: height("function-HeroLead.png", 2);
                        }

                        // タブレット
                        @include mq(tablet, desktop) {
                            width: auto;
                            height: height("function-HeroLead.png", 2);
                        }

                    }

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: -48px;
                        left: calc(50% - 40px);
                        width: 80px;
                        height: 8px;
                        background-color: rgba($YLW, 1);

                        @include mq(mobile, desktop) {
                            width: 40px;
                            left: calc(50% - 20px);
                            height: 4px;
                            bottom: -24px;
                        }

                    }

                }

            }

            &-tab {

            }

        }

    }

}
