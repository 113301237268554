.page-top {

    .l-serviceContents {
        // .l-serviceContents__Lead
        &__Lead {
            left: 10%;
            margin-top: -200px;
            position: absolute;
            text-align: center;
            top: 50%;

            // モバイル
            @include mq(mobile, desktop) {
                left: 0;
                margin-top: auto;
                position: relative;
                text-align: center;
                top: auto;
                margin-top: 20px;
                padding: 0 10px;
            }


            // .l-serviceContents__Lead-name
            &-name {
                img {
                    width: auto;
                    height: 40px;
                }
            }

            // .l-serviceContents__Lead-heading
            &-heading {
                @include font-size(24);

                line-height: 1.4;
                color: rgba($GRN, 1);
                margin: 30px 0 20px;

                // モバイル
                @include mq(mobile, desktop) {
                    margin: 20px 0 10px;
                }
            }

            // .l-serviceContents__Lead-body
            &-body {
                @extend %font-16_grn;

                font-weight: bold;

                // モバイル
                @include mq(mobile, desktop) {
                    @include font-size(12);

                    line-height: 1.6;
                    color: rgba($GRN, 1);
                }
            }

            // .l-serviceContents__Lead-btn
            &-btn {
                @include font-size(16);

                background-color: rgba($GRN, 1);
                border-radius: 6px;
                color: rgba($WHT, 1);
                display: block;
                font-weight: bold;
                margin: 20px 0;
                padding: 20px 0;
                text-decoration: none;
                width: 100%;

                @extend %blurbox;

                // モバイル
                @include mq(mobile, desktop) {
                    margin: 20px 0 0 10px;
                    width: calc(100% - 20px);
                }

                &:hover {
                    background-color: rgba($WHT, 1);
                    color: rgba($GRN, 1);

                    @extend %blurbox_down;
                }

                &:active {
                    @extend %blurbox0;
                }
            }
        }
    }

}
