.l-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
}

.l-drawer {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &__Backdrop {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    &__Nav {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 280px;
        overflow: auto;
    }
}

/*展開したときの挙動*/
.l-drawer[aria-expanded] {
    transition-property: visibility;
    transition-duration: 0.25s;
}

.l-drawer[aria-expanded] .l-drawer__Backdrop {
    transition-property: opacity;
    transition-duration: 0.25s;
    transition-timing-function: linear;
}

.l-drawer[aria-expanded] .l-drawer__Nav {
    transition-property: transform;
    transition-duration: 0.25s;
    transition-timing-function: ease;
}

/* 開いているとき */
.l-drawer[aria-expanded="true"] {
    visibility: visible;
}

.l-drawer[aria-expanded="true"] .l-drawer__Backdrop {
    opacity: 1;
}

.l-drawer[aria-expanded="true"] .l-drawer__Nav {
    transform: translateX(0);
}

/* 閉じているとき */
.l-drawer[aria-expanded="false"] {
    visibility: hidden;
}

.l-drawer[aria-expanded="false"] .l-drawer__Backdrop {
    opacity: 0;
}

.l-drawer[aria-expanded="false"] .l-drawer__Nav {
    transform: translateX(100%);
}