.page-function {
    .l-functionFeature {
        &__Innr {
            padding: 0;

            &-body {
                position: relative;
                width: 100%;
            }
        }
    }
}
