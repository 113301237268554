@charset "utf-8";

.data-link {
    cursor: pointer;
}

.c-btn__text {
    @include font-size(15);
    color: $BLK;
    text-decoration: none !important;
    border-bottom: 1px solid $BLK;
    position: relative;
    width: auto;
    display: inline-block;

    // モバイル
    @include mq($from: mobile, $until: desktop) {
        @include font-size(13);
    }

    &:hover {
        text-decoration: none;
        border-bottom: 0px solid $BLK;
        color: $HOV;
    }

    &:before {
        font-family: "SSGizmo";

        position: absolute;
        @include font-size(15);
        top: 1px;
        right: -20px;
        font-weight: normal;

        @include mq($from: mobile, $until: desktop) {
            @include font-size(12);
            right: -16px;
            top: 2px;
        }
    }
}
