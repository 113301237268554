.page-ojt {
    .l-supportMenu {
        &__Innr {
            margin-top: 140px;

            // モバイル
            @include mq(mobile, desktop) {
                margin-top: 80px;
            }

            &-body {
                border-radius: 8px;
                width: 1120px;
                margin: 40px auto 0;

                // モバイル
                @include mq(mobile, desktop) {
                    width: 100%;
                    padding: 10px;
                    margin: 20px auto 0;
                }
            }
        }
    }
}
