.page-newEmployee {
    .l-qualityItem {
        width: 50%;
        overflow: hidden;
        display: block;
        position: relative;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 0 0;

        &__Innr {
            height: 240px;
            padding: 35px 0 30px 30px;

            // モバイル
            @include mq(mobile, desktop) {
                height: 160px;
                padding: 10px 0 10px 0;
            }
        }

        &__Lead {
            margin: 16px 0 15px;
            text-align: center;

            // モバイル
            @include mq(mobile, desktop) {
                margin: 10px 0 15px;
            }

            img {
                width: auto;
                height: 48px;

                // モバイル
                @include mq(mobile, desktop) {
                    height: 20px;
                }
            }

            &-exp {
                text-align: center;
                font-weight: bold;
                margin-top: 20px;
                @include font-size(16);
                color: rgba($WHT, 1);

                // モバイル
                @include mq(mobile, desktop) {
                    margin-top: 10px;

                    @include font-size(11);
                }
            }
        }

        &.l-qualityNo_1 {
            background-image: linear-gradient(135deg, rgba(14, 173, 169, 1) 0%, rgba(110, 230, 196, 1) 100%);
        }

        &.l-qualityNo_2 {
            background-image: linear-gradient(135deg, rgba(254, 219, 142, 1) 0%, rgba(253, 143, 135, 1) 100%);
        }

        &::before {
            content: "";
            z-index: 2;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-style: solid;
            border-width: 360px 0 0 454px;
            border-color: transparent transparent transparent rgba($WHT, 0.1);

            // モバイル
            @include mq(mobile, desktop) {
                border-width: 170px 0 0 160px;
            }
        }
    }
}
