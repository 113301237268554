.page-top {
    .l-concept {
        background-image: url("../img/top-conceptPart-plain.png"), url("../img/top-conceptPart-sun.png"), url("../img/bg-concept.png");
        background-position: left 100px top 60px, right 100px top 60px, 0 0;
        background-repeat: no-repeat, no-repeat, repeat;
        background-size: 284px 145px, 299px 145px, 1326px 750px;
        border-left: 20px solid rgba($WHT, 1);
        border-right: 20px solid rgba($WHT, 1);
        margin: 20px 0;
        padding: 0 20px;
        position: relative;
        width: calc(100%);

        @include mq(mobile, desktop) {
            background-image: url("../img/bg-concept.png");
            background-position: 0 0;
            background-repeat: repeat;
            background-size: none;
            border-left: 0px solid rgba($WHT, 1);
            border-right: 0px solid rgba($WHT, 1);
            margin: 10px 0;
            padding: 0 20px 280px;
        }


        // モバイル・ランドスケープ
        @include mq($until: tablet, $and: "(orientation: landscape)") {
            padding: 0 20px 360px;
        }


        // タブレット
        @include mq(tablet, desktop) {
            padding: 0 20px 440px;
        }


        &__Innr {
            padding-top: 80px;

            @include mq($from: mobile, $until: tablet) {
                padding-top: 40px;
            }


            // モバイル
            @include mq(mobile, desktop) {
                padding-top: 40px;
            }


            // モバイル・ランドスケープ
            @include mq($until: tablet, $and: "(orientation: landscape)") {
                padding-top: 40px;
            }


            // タブレット
            @include mq(tablet, desktop) {
                padding-top: 60px;
            }
        }

        &::before {
            position: absolute;
            bottom: 50px;
            left: 0;
            content: "";
            width: 100%;
            height: 20px;
            border-bottom: 50px solid rgba(184, 233, 134, 1);
        }

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: "";
            width: 100%;
            height: 20px;
            border-bottom: 50px solid rgba(232, 250, 196, 1);
        }
    }
}
