$side-menu-width: 300px;
$animation: .3s ease-in-out;
$offcanvas-overlay: rgba(0, 0, 0, 0.75);
$offcanvas-overlay-focused: rgba(0, 0, 0, 0.75);


.js-hiraku-offcanvas-active {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100001;
	width: 100%;
	height: 100%;
	background: $offcanvas-overlay;
}

.js-hiraku-offcanvas-open {
	display: block;
}

.js-hiraku-offcanvas-body {
	width: 100%;
	left: 0;
	transition: left $animation;
	z-index: 0;
}

.js-hiraku-offcanvas-body-right {
	overflow-y: hidden;
	left: (- $side-menu-width);
	position: fixed;
}

.js-hiraku-offcanvas-body-left {
	overflow-y: hidden;
	left: $side-menu-width;
	position: fixed;
}

.js-hiraku-offcanvas-body-moving {
	left: 0;
	position: fixed;
}

.js-hiraku-offcanvas-active .js-hiraku-offcanvas-sidebar {
	display: block;
	width: $side-menu-width;
	z-index: 10002;
	position: fixed;
	top: 0;
	bottom: 0;
	max-width: 100%;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	box-sizing: border-box;
}

.js-hiraku-offcanvas:focus {
	background-color: $offcanvas-overlay-focused;
}

.js-hiraku-offcanvas-active .js-hiraku-offcanvas-sidebar-right {
	right: 0;
	left: auto;
	margin-right: (- $side-menu-width);
	transition: margin-right $animation;
}

.js-hiraku-offcanvas-active .js-hiraku-offcanvas-sidebar-left {
	left: 0;
	right: auto;
	margin-left: (- $side-menu-width);
	transition: margin-left $animation;
}

.js-hiraku-offcanvas-active .js-hiraku-offcanvas-sidebar-right.active {
	margin-right: 0;
	transition: margin-right $animation;
}

.js-hiraku-offcanvas-active .js-hiraku-offcanvas-sidebar-left.active {
	margin-left: 0;
	transition: margin-left $animation;
}

.js-hiraku-header-fixed {
	transition: margin-left $animation;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
}

.js-hiraku-offcanvas-body-right,
.js-hiraku-offcanvas-body-left {
	overflow: hidden;
}

.js-hiraku-offcanvas-body-right .js-hiraku-header-fixed {
	margin-left: (- $side-menu-width);
}

.js-hiraku-offcanvas-body-left .js-hiraku-header-fixed {
	margin-left: $side-menu-width;
}

.js-hiraku-offcanvas-close-btn {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	overflow: hidden;
	clip: rect(0 0 0 0);
}


.hiraku-open-btn {
    background: transparent;
    padding: 6px;
    cursor: pointer;
    border: none;
}

.hiraku-open-btn-line {
    display: block;
    position: relative;
    width: 40px;
    height: 2px;
    margin: 6px 0;
    background-color: #fff;
    transition: .1s all;
}

.hiraku-open-btn-line:before,
.hiraku-open-btn-line:after {
    content: "";
    display: block;
    position: absolute;
    background-color: #fff;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    transition: .3s all;
}

.hiraku-open-btn-line:before {
    top: -6px;
}

.hiraku-open-btn-line:after {
    bottom: -6px;
}

[aria-expanded="true"] .hiraku-open-btn-line {
    background-color: transparent;
}

[aria-expanded="true"] .hiraku-open-btn-line:before,
[aria-expanded="true"] .hiraku-open-btn-line:after {
    width: 44px;
}

[aria-expanded="true"] .hiraku-open-btn-line:before {
    transform: translate(-2px, 6px) rotate(45deg);
}

[aria-expanded="true"] .hiraku-open-btn-line:after {
    transform: translate(-2px, -6px) rotate(-45deg);
}
