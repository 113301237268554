html {
    -webkit-overflow-scrolling: touch
}
body {
    position: relative;
    -webkit-overflow-scrolling: touch
}
::-moz-selection { color: #FFF;  background: $GRN_Chiesta; }
::selection      { color: #FFF;  background: $GRN_Chiesta; }

p::-moz-selection { color: white;  background: black; }
p::selection      { color: white;  background: black; }

.l-wrapper{
  position: relative;
  margin: 0;
}

// @include mq($from: mobile) {
//   .l-contents{
//     width: 280px;
//     margin: 0 auto;
//   }
// }
// @include mq($from: tablet,$until: desktop) {
//   .l-contents{
//     width: 640px;
//     margin: 0 auto;
//   }
// }
// @include mq($from: desktop) {
//   .l-contents{
//     width: 100%;
//   }
// }
