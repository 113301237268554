.page-top {
    .l-service {
        // background: $GRN_LIGHTEST url("../img/top-serviceBrowser.png") no-repeat right 20px top 140px;
        background: $GRY_LIGHTEST;
        margin: 20px;
        min-height: 640px;
        overflow: hidden;
        padding: 80px 20px 0;
        position: relative;
        width: calc(100% - 40px);

        // モバイル
        @include mq(mobile, desktop) {
            padding: 40px 0 280px;
            margin: 0;
            min-height: auto;
            width: calc(100%);
        }
    }

}
