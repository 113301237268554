.c-contactContents {
    margin-top: 40px;

    // モバイル
    @include mq(mobile, desktop) {
        margin-top: 20px;
    }

    &__List {
        width: 100%;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: stretch;
        justify-content: center;

        @include mq($from: mobile, $until:desktop) {
            display: block;
            min-height: auto;
            border-top: 1px solid rgba($GRY_LIGHTER, 1);
            padding: 0 20px;
        }

        &-box {
            width: 33.333%;
            text-align: center;
            border-top: 1px solid rgba($GRY_LIGHTER, 1);
            border-bottom: 1px solid rgba($GRY_LIGHTER, 1);

            @include mq($from: mobile, $until:desktop) {
                width: 100%;
                border-top: 0px solid rgba($GRY_LIGHTER, 1);
                border-bottom: 1px solid rgba($GRY_LIGHTER, 1);
            }
            &.address {
                background-color: $WHT;
                color: rgba($GRN, 1);
            }
            &.telephone {
                background-color: $WHT;
                border-left: 1px solid rgba($GRY_LIGHTER, 1);
                border-right: 1px solid rgba($GRY_LIGHTER, 1);

                @include mq($from: mobile, $until:desktop) {
                    border-left: 0px solid rgba($WHT, 1);
                    border-right: 0px solid rgba($WHT, 1);
                    margin: 0;
                }
            }
            &.message {
                @include mq($from: mobile, $until:desktop) {
                    border-bottom: 1px solid rgba($GRY_LIGHTER, 1);
                }
            }
            img {
                width: auto;
                height: 31px;
            }
        }

        &-link {
            color: rgba($WHT, 1);
            background-color: $WHT;
            display: block;
            width: 100%;
            height: 100%;
            cursor: pointer;
            text-decoration: none;

            &:hover {
                background-color: $GRY_LIGHTEST;
                text-decoration: none;
            }
        }

        &-nolink {
            color: rgba($GRN, 1);
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &__Item {
        padding: 36px 0;

        @include mq($from: mobile, $until:desktop) {
            padding: 20px 0;
        }

        &-icon {
            height: 32px;
            margin-bottom: 4px;

            @include mq($from: mobile, $until:desktop) {
                margin-bottom: 0px;
            }
        }

        span {
            @include font-size(10);
            @extend %f-en_m;
        }

        h6 {
            @include font-size(16);
            @extend %f-en_b;
            color: rgba($GRN, 1);
        }

        li {
            @include font-size(12);
            @extend %f-en_b;
            color: rgba($GRN, 1);
        }

        .enFont_b {
            @include font-size(16);
            @extend %f-en_b;
            color: rgba($GRN, 1);
        }
    }
}
