.page-ojt {
    .l-graph {
        margin-top: -2px;
        background: url("../img/icon-bottomNoBorderGrn.png") no-repeat center (top / 41px) 15px;
        padding-top: 80px;

        // モバイル
        @include mq(mobile, desktop) {
            display: none;
        }
        // モバイル・ランドスケープ
        @include mq($until: tablet, $and: "(orientation: landscape)") {
            display: none;
        }
        // タブレット
        @include mq(tablet, desktop) {
            display: block;
        }
    }

    $CCP: #C37FC4;
    $CCR: #FD9187;

    .l-tippedBody {
        display: none;

        &__Icon {
            text-align: center;
            margin-bottom: 3px;

            img {
                width: auto;
                height: 48px;
            }
        }

        &__Heading {
            @include font-size(16);

            line-height: 1.4;
            text-align: center;
        }

        &__Body {}
    }

    .l-graphBody {
        background: transparent url("../img/ojt-chart__large.png") center center (no-repeat / 1080px) 475px;
        width: 1084px;
        height: 475px;
        margin: 40px auto 0;
        position: relative;

        &__circle {
            width: 30px;
            height: 30px;
            border-radius: 15px;
            position: absolute;
            transition: all .1s ease-in-out;
            opacity: 1;

            &:hover {
                opacity: .7;
            }

            &.cNo_1 {
                background-color: rgba($CCP, 1);
                top: 204px;
                left: 34px;
            }

            &.cNo_2 {
                background-color: rgba($CCR, 1);
                top: 151px;
                left: 103px;
            }

            &.cNo_3 {
                background-color: rgba($CCR, 1);
                top: 138px;
                left: 132px;
            }

            &.cNo_4 {
                background-color: rgba($CCR, 1);
                top: 136px;
                left: 258px;
            }

            &.cNo_5 {
                background-color: rgba($CCR, 1);
                top: 156px;
                left: 330px;
            }

            &.cNo_6 {
                background-color: rgba($CCR, 1);
                top: 171px;
                left: 395px;
            }

            &.cNo_7 {
                background-color: rgba($CCR, 1);
                top: 176px;
                left: 464px;
            }

            &.cNo_8 {
                background-color: rgba($CCR, 1);
                top: 72px;
                left: 577px;
            }

            &.cNo_9 {
                background-color: rgba($CCR, 1);
                top: 86px;
                left: 694px;
            }

            &.cNo_10 {
                background-color: rgba($CCR, 1);
                top: 115px;
                left: 789px;
            }

            &.cNo_11 {
                background-color: rgba($CCR, 1);
                top: 122px;
                left: 874px;
            }

            &.cNo_12 {
                background-color: rgba($CCR, 1);
                top: 132px;
                left: 946px;
            }

            &.cNo_13 {
                background-color: rgba($CCR, 1);
                top: 150px;
                left: 1020px;
            }
        }
    }
}
