.page-function {
.l-coverItem {
    flex: 1 0 50%;
    width: 50%;

    @include mq(mobile, desktop) {
        width: 100%;
    }

    &__Innr {
        display: block;
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
        &-link {
            width: 100%;
            display: flex;
            align-items: center;
            background-color: rgba($BLK, .25);
            justify-content: center;
            color: rgba($WHT, 1);
            text-align: center;
            padding: 24px 16px;

            @include mq(mobile, desktop) {
                justify-content: center;
                padding: 10px;
            }

            h6 {
                @include font-size(16);
                @extend .f-en_b;

                @include mq(mobile, desktop) {
                    order: 2;
                    @include font-size(12);
                }
            }

            img {
                width: auto;
                height: height("iconService_02A.png", 4);

                @include mq(mobile, desktop) {
                    width: auto;
                    height: height("iconService_02A.png", 6);
                }
            }
        }
    }


    &:hover {
        background-color: rgba($GRN, .5);
    }



    &.is-topic_1 {
        border-radius: 8px 0 0 0;
        margin-bottom: 2px;
        margin-right: 1px;
        .l-coverItem__Innr-link {
            border-radius: 8px 0 0 0;
            h6 {
                order: 2;
                margin-left: 16px;
            }
            img {
                order: 1;
            }
        }
    }

    &.is-topic_2 {
        border-radius: 0 0 0 8px;
        margin-bottom: 2px;
        margin-right: 1px;
        .l-coverItem__Innr-link {
            border-radius: 0 0 0 8px;
            h6 {
                order: 2;
                margin-left: 16px;
            }
            img {
                order: 1;
            }
        }
    }

    &.is-movie_1 {
        border-radius: 0 8px 0 0;
        margin-left: 1px;
        .l-coverItem__Innr-link {
            border-radius: 0 8px 0 0;
            h6 {
                order: 1;
            }
            img {
                order: 2;
                margin-left: 16px;
            }
        }
    }

    &.is-movie_2 {
        border-radius: 0 0 8px 0;
        margin-left: 1px;
        .l-coverItem__Innr-link {
            border-radius: 0 0 8px 0;
            h6 {
                order: 1;
            }
            img {
                margin-left: 16px;
                order: 2;
            }
        }
    }
}
}
