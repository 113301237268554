.page-function {
    .l-serviceList {
        padding: 120px 20px 0 20px;
        position: relative;

        // モバイル
        @include mq(mobile, desktop) {
            padding: 32px 0 0 0;
        }

        &.is-banner{
            padding: 20px 20px 0 20px;

            // モバイル
            @include mq(mobile, desktop) {
                padding: 10px 10px 0 10px;
            }
            .l-serviceList__innr-function{
                height: 320px;
                border: 1px solid rgba($GRY_LIGHTEST, 1);
                // モバイル
                @include mq(mobile, desktop) {
                    height: 184px;
                }
            }
            .l-serviceList__innr-functionLink{
                /*background-image: linear-gradient(0deg, rgba(197, 213, 232, 1) 0%, rgba(193, 228, 223, 1) 100%);
                background-position: 0 0;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                overflow: hidden;*/

                height: 320px;

                // モバイル
                @include mq(mobile, desktop) {
                    height: 184px;
                }
            }
        }

        &__innr {
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            align-items: center;
            justify-content: center;

            &.is-second {
                padding-top: 2px;
            }

            // モバイル
            @include mq(mobile, desktop) {
                display: block;
            }

            &-parts {

                .title {
                    display: -webkit-flex;
                    display: -moz-flex;
                    display: -ms-flex;
                    display: -o-flex;
                    display: flex;
                    align-items: center;
                }

                &.is-function {
                    span {
                        margin-right: 24px;

                        // モバイル
                        @include mq(mobile, desktop) {
                            margin-right: 12px;
                        }

                        img {
                            width: auto;
                            height: height("iconService_01A.png", 3);

                            // モバイル
                            @include mq(mobile, desktop) {
                                width: auto;
                                height: height("iconService_01A.png", 6);
                            }
                        }
                    }

                    h6 {
                        img {
                            width: auto;
                            height: height("functionServiceLeadGrn_sp.png", 2);

                            // モバイル
                            @include mq(mobile, desktop) {
                                width: auto;
                                height: height("functionServiceLeadGrn_sp.png", 4);
                            }
                        }
                    }
                }

                .wrap {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 12px;
                    @include font-size(16);

                    // モバイル
                    @include mq(mobile, desktop) {
                        margin-bottom: 8px;
                        @include font-size(13);
                    }

                    .wrapIcon {
                        margin-right: 8px;
                        color: rgba($GRN, 1);

                        &:before {
                            @include font-size(24);
                            margin-top: -4px;

                            // モバイル
                            @include mq(mobile, desktop) {
                                @include font-size(18);
                            }
                        }
                    }

                    span {
                        color: rgba($GRN, 1);
                        @extend .f-en_b;
                        margin-bottom: -12px;

                        // モバイル
                        @include mq(mobile, desktop) {
                            margin-bottom: -8px;
                        }
                    }
                }

                .caption {
                    border-radius: 32px;
                    color: rgba($GRN, 1);
                    border: 2px solid rgba($GRN, 1);
                    background-color: rgba($WHT, 1);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    position: relative;
                    width: 144px;
                    height: 40px;
                    @include font-size(14);
                    @extend %f-jp_b;
                    margin-top: 16px;
                    margin: 16px auto 0;

                    // モバイル
                    @include mq(mobile, desktop) {
                        @include font-size(11);
                        margin-top: 6px;
                        width: 112px;
                        height: 32px;
                    }

                    .partsIcon {
                        position: absolute;
                        top: calc(50% - 8px);
                        right: 6px;

                        &:before {
                            color: rgba($GRN, 1);
                            @include font-size(16);
                        }
                    }
                }
            }

            &-function {
                border: 1px solid rgba($GRY_LIGHTER, 1);
                width: 100%;
                height: 480px;

                &:first-child {
                    border-right: none;

                    // モバイル
                    @include mq(mobile, desktop) {
                        border-right: 1px solid rgba($GRY_LIGHTER, 1);
                        border-bottom: none;
                    }
                }

                // モバイル
                @include mq(mobile, desktop) {
                    height: 184px;
                }
            }

            &-functionLink {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 480px;
                padding: 0 32px;
                position: relative;
                background-color: rgba($GRN_LLL, 1);

                &:hover {
                    text-decoration: none;
                    background-color: rgba($WHT, 1);
                }

                // モバイル
                @include mq(mobile, desktop) {
                    height: 184px;
                    padding: 0;
                }
            }


            &-service {
                width: 100%;
                height: 480px;

                // タブレット
                @include mq(mobile, desktop) {
                    height: 240px;
                }

                &:hover {
                    opacity: .75;
                }

                &.is-newEmployee {
                    border-right: 1px solid rgba($WHT, 1);
                    background: url("../img/top-serviceBg_1.png") no-repeat center (center / cover);

                    // タブレット
                    @include mq(mobile, desktop) {
                        border-right: none;
                        border-bottom: 2px solid rgba($WHT, 1);
                    }

                    img {
                        width: auto;
                        height: height("top-serviceLead_1.png", 2);
                        // タブレット
                        @include mq(mobile, desktop) {
                            width: auto;
                            height: height("top-serviceLead_1.png", 3);
                        }
                    }
                }

                &.is-ojt {
                    border-left: 1px solid rgba($WHT, 1);
                    background: url("../img/top-serviceBg_2.png") no-repeat center (center / cover);

                    // タブレット
                    @include mq(mobile, desktop) {
                        border-left: none;
                    }

                    img {
                        width: auto;
                        height: height("top-serviceLead_2.png", 2);
                        // タブレット
                        @include mq(mobile, desktop) {
                            width: auto;
                            height: height("top-serviceLead_2.png", 3);
                        }
                    }
                }

            }

            &-serviceLink {
                display: flex;
                position: relative;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 480px;

                &:hover{
                    text-decoration: none;
                    .caption{
                        text-decoration: none;
                    }
                }

                &:before {
                    position: absolute;
                    top: calc(50% - 8px);
                    right: 12px;
                    @include font-size(16);
                    color: rgba($WHT, 1);
                }

                // モバイル
                @include mq(mobile, desktop) {
                    height: 240px;
                }
            }

        }
    }
}
