@charset "utf-8";

.c-header {
    background-color: rgba($WHT, 1);
    width: 100%;
    padding: 11px 0;
    height: 64px;
    position: relative;
    border-bottom: 1px solid rgba($GRY_LIGHTER, 1);

    &.headroom {
        will-change: transform;
        transition: transform 200ms linear;
    }

    &.headroom--not-bottom {
        display: block;
    }

    &.headroom--not-top {
        display: block;

        &.headroom--pinned {
            transform: translateY(0%);
            z-index: 9999;
            position: fixed;
            box-shadow: 0 0 8px 0 rgba($BLK, 0.1);

        }
        .js-hiraku-offcanvas-body-right & {
            display: none;
        }
    }

    .page-top & {
        &.headroom--top {
            display: none;
        }

    }

    // モバイル
    @include mq(mobile, desktop) {
        height: 44px;
        padding: 8px 0;
    }


    // モバイル・ランドスケープ
    @include mq($until: tablet, $and: "(orientation: landscape)") {
        height: 44px;
        padding: 8px 0;
    }


    // タブレット
    @include mq(tablet, desktop) {
        height: 44px;
        padding: 8px 0;
    }


    &__Innr {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;

        // モバイル
        @include mq(mobile, desktop) {}


        // モバイル・ランドスケープ
        @include mq($until: tablet, $and: "(orientation: landscape)") {}


        // タブレット
        @include mq(tablet, desktop) {}
    }

    &__Trigger {
        position: absolute;
        top: calc(50% - 13px);
        right: 20px;

        &.is-color {
            .hiraku-open-btn-line {
                background-color: rgba($GRN, 1) !important;

                .js-hiraku-offcanvas-body-right & {
                    background-color: rgba($GRN, 0) !important;
                }

                &:before,
                &:after {
                    background-color: rgba($GRN, 1) !important;
                }
            }


        }
    }

    &__Logo {
        img {
            height: 38px;

            // モバイル
            @include mq(mobile, desktop) {
                height: 22px;
            }


            // モバイル・ランドスケープ
            @include mq($until: tablet, $and: "(orientation: landscape)") {
                height: 26px;
            }


            // タブレット
            @include mq(tablet, desktop) {
                height: 30px;
            }
        }
    }

    &__Nav {
        &-wrap {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: center;
            margin: 0;
        }

        &-item {
            margin-right: 30px;
            font-weight: bold;

            @include font-size(15);


            // モバイル
            @include mq(mobile, desktop) {
                @include font-size(12);
                margin-right: 10px;
            }

            // モバイル・ランドスケープ
            @include mq($until: tablet, $and: "(orientation: landscape)") {
                @include font-size(13);
                margin-right: 14px;
            }

            // タブレット
            @include mq(tablet, desktop) {
                @include font-size(14);
                margin-right: 20px;
            }

            a {
                @extend .f-jp_b;
                text-decoration: none;
                color: rgba($GRN, 1);
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__wsLogo {
        margin-top: -5px;

        // モバイル
        @include mq(mobile, desktop) {
            display: none;
        }


        // モバイル・ランドスケープ
        @include mq($until: tablet, $and: "(orientation: landscape)") {
            display: none;
        }


        // タブレット
        @include mq(tablet, desktop) {
            width: 160px;
            height: auto;
        }


        img {
            height: 22px;
        }
    }
}
