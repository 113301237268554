.page-ojt {
    .l-feature {
        background-color: rgba($GRY_LIGHTEST, 1);
        &__Innr {
            margin-top: -2px;
            background: url("../img/icon-bottomNoBorder.png") no-repeat center top / 41px 15px;
            display: flex;
            align-items: strech;
            flex-wrap: wrap;

            // モバイル
            @include mq(mobile, desktop) {
                display: block;
                padding: 10px;
            }

            &-title {}

            &-item {
                flex: 1 0 33.333%;
                background-color: rgba($WHT, 1);
                position: relative;

                // モバイル
                @include mq(mobile, desktop) {
                    flex: 1 0 100%;
                }

                &:after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 60px 60px 0;
                    border-color: transparent $GRY_LIGHTEST transparent transparent;
                }

                &:before {
                    position: absolute;
                    z-index: 10;
                    top: 8px;
                    right: 12px;
                    @include font-size(15);

                    @extend .f-en_b;
                    color: rgba($GRN, 1);
                }


                &.is-no00 {
                    &:before {
                        content: none;
                    }

                    &:after {
                        content: none;
                    }
                }


                &.is-no01 {
                    &:before {
                        content: "1";
                    }
                }

                &.is-no02 {
                    &:before {
                        content: "2";
                    }
                }

                &.is-no03 {
                    &:before {
                        content: "3";
                    }
                }

                &.is-no04 {
                    &:before {
                        content: "4";
                    }
                }

                &.is-no05 {
                    &:before {
                        content: "5";
                    }
                }

                &.is-no00,
                &.is-no01,
                &.is-no02 {
                    margin-bottom: 10px;

                    // モバイル
                    @include mq(mobile, desktop) {}

                }

                &.is-no03,
                &.is-no04,
                &.is-no05 {
                    margin-bottom: 0px;

                    // モバイル
                    @include mq(mobile, desktop) {
                        margin-bottom: 10px;
                    }

                }

                &.is-no01,
                &.is-no04 {
                    border-left: 10px solid rgba($GRY_LIGHTEST, 1);
                    border-right: 10px solid rgba($GRY_LIGHTEST, 1);

                    // モバイル
                    @include mq(mobile, desktop) {
                        border: none;
                    }

                }

                .c-secHeading {
                    margin-top: 80px;

                    // モバイル
                    @include mq(mobile, desktop) {
                        margin-top: 10px;
                        padding-top: 20px;
                    }
                }

                .c-secHeading__Title {
                    margin-bottom: 0;

                    // モバイル
                    @include mq(mobile, desktop) {
                        padding-top: 40px;
                    }
                    &:before {

                        // モバイル
                        @include mq(mobile, desktop) {
                            top: 0px !important;
                        }
                    }
                }
            }

            &-photo {
                width: 100%;
                height: 144px;
                background: #FFF url("../img/service-OjtBrowser.png") no-repeat 0 (0 / cover);

                // モバイル
                @include mq(mobile, desktop) {}
            }
        }
    }
}
