    .l-stepContents {
        margin-top: 40px;

        // モバイル
        @include mq(mobile, desktop) {
            margin-top: 20px;
        }

        &__Steppr {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: stretch;
            position: relative;

            // モバイル
            @include mq(mobile, desktop) {
                border-bottom: 3px solid rgba($GRY_LIGHTEST, 1);
                display: block;
            }

            &-item {
                position: relative;
                width: 25%;
                margin-right: 20px;

                // モバイル
                @include mq(mobile, desktop) {
                    width: 100%;
                    margin-right: 0px;
                }

                &:after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 60px 60px 0;
                    border-color: transparent $GRY_LIGHTEST transparent transparent;
                }

                &:before {
                    content: "";
                    position: absolute;
                    z-index: 10;
                    top: 8px;
                    right: 12px;
                    @include font-size(15);
                    @extend .f-en_b;
                    color: rgba($GRN, 1);
                }

                &.is-no01 {
                    &:before {
                        content: "1";
                    }
                }

                &.is-no02 {
                    &:before {
                        content: "2";
                    }
                }

                &.is-no03 {
                    &:before {
                        content: "3";
                    }
                }

                &.is-no04 {
                    &:before {
                        content: "4";
                    }
                }

                &.is-no05 {
                    &:before {
                        content: "5";
                    }
                }

                &.is-multi {
                    padding: 18px 20px 0;
                }

                &:first-child {
                    .l-stepContents__Steppr-title {
                        &:after {
                            // モバイル
                            @include mq(mobile, desktop) {
                                content: none;
                            }
                        }
                    }
                }

                &:last-child {
                    margin-right: 0;

                    .l-stepContents__Steppr-title {
                        &:after {
                            content: none;

                            // モバイル
                            @include mq(mobile, desktop) {
                                content: "";
                            }
                        }
                    }
                }
            }

            &-title {
                @include font-size(16);
                font-weight: bold;
                color: rgba($GRN, 1);
                background-color: rgba($WHT, 1);
                border: 3px solid rgba($GRY_LIGHTEST, 1);
                padding: 15px 24px;
                position: relative;

                // モバイル
                @include mq(mobile, desktop) {
                    @include font-size(15);
                }

                &.is-multi {
                    padding: 8px 24px;
                    line-height: 1.2;

                    // モバイル
                    @include mq(mobile, desktop) {
                        padding: 15px 24px;
                    }
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 64px;
                    right: -19px;
                    width: 16px;
                    height: 12px;
                    border-style: solid;
                    border-width: 16px 0 16px 12px;
                    border-color: transparent transparent transparent $YLW;

                    // モバイル
                    @include mq(mobile, desktop) {
                        position: absolute;
                        top: 0;
                        right: auto;
                        left: calc(50% - 3px);

                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                        border-color: rgba(0, 0, 0, 0);
                        border-top-color: $YLW;
                        border-width: 6px;
                        margin-left: -6px;
                    }
                }
            }

            &-body {
                @include font-size(12);
                line-height: 1.8;
                color: rgba($GRY_DARKER, 1);
                min-height: 117px;
                padding: 15px 20px;
                border-left: 3px solid rgba($GRY_LIGHTEST, 1);
                border-right: 3px solid rgba($GRY_LIGHTEST, 1);
                border-bottom: 3px solid rgba($GRY_LIGHTEST, 1);

                // モバイル
                @include mq(mobile, desktop) {
                    border-bottom: 0px solid rgba($GRY_LIGHTEST, 1);

                    &:last-child {}
                }
            }
        }
    }
