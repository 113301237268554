    .l-stepPoint {
        background-color: rgba($GRY_LIGHTEST, 1);
        padding: 10px;
        margin-top: 20px;

        // モバイル
        @include mq(mobile, desktop) {
            margin-top: 10px;
            padding: 3px;
        }

        &__Innr {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: stretch;

            // モバイル
            @include mq(mobile, desktop) {
                display: block;
            }


            &-item {

                position: relative;
                padding: 30px;
                background-color: rgba($WHT, 1);

                // モバイル
                @include mq(mobile, desktop) {
                    padding: 20px;
                }

                &:nth-child(2) {
                    margin: 0 10px;

                    // モバイル
                    @include mq(mobile, desktop) {
                        margin: 3px 0 3px 0;
                    }
                }

                &:first-child {
                    border-radius: 3px 0 0 3px;
                }

                &:last-child {
                    border-radius: 0 3px 3px 0;
                }
            }

            &-title {
                @include font-size(18);

                line-height: 1.2;
                color: rgba($GRN, 1);
            }

            &-desc {
                @include font-size(13);

                line-height: 1.8;
                color: rgba($GRY, 1);
                margin: 10px 0;
            }

            &-caution {
                @include font-size(12);

                line-height: 1.2;
                color: rgba($GRN, 1);

                i {
                    margin-right: 6px;
                }
            }
        }
    }
