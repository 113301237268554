.page-newEmployee {
    .l-featureItem {
        background-color: rgba($WHT, 1);
        min-width: 320px;
        width: 33.333%;

        // モバイル
        @include mq(mobile, desktop) {
            min-width: 300px;
            width: 100%;
            margin-bottom: 10px;
        }


        &:nth-child(2) {
            margin: 0 20px;

            // モバイル
            @include mq(mobile, desktop) {
                margin: 0 0 10px 0;
            }
        }

        &:last-child {

            // モバイル
            @include mq(mobile, desktop) {
                margin: 0 0 0px 0;
            }

        }

        &__Photo {
            height: 240px;
            text-align: center;
            border-bottom: 1px solid $GRY_LIGHTEST;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;

            // モバイル
            @include mq(mobile, desktop) {
                height: 160px;
            }
        }

        &.l-featureItemNo_1 {
            .l-featureItem__Photo {
                background-image: url("../img/service-feature_1.jpg");
            }
        }

        &.l-featureItemNo_2 {
            .l-featureItem__Photo {
                background-image: url("../img/service-feature_2.jpg");
            }
        }

        &.l-featureItemNo_3 {
            .l-featureItem__Photo {
                background-image: url("../img/service-feature_3.jpg");
            }
        }

        &__Lead {
            padding: 40px 20px 20px 20px;
            position: relative;
            text-align: left;

            // モバイル
            @include mq(mobile, desktop) {
                padding: 20px 15px 15px 15px;
            }

            &-genre {
                position: absolute;
                top: -20px;

                @include font-size(16);

                background-color: rgba($GRN, 1);
                padding: 8px 20px 10px;
                text-align: center;
                border-radius: 6px;
                color: rgba($WHT, 1);

                // モバイル
                @include mq(mobile, desktop) {
                    @include font-size(14);

                    padding: 6px 20px 6px;
                }
            }

            &-heading {
                @include font-size(20);

                color: rgba($GRN, 1);
                margin-bottom: 6px;

                // モバイル
                @include mq(mobile, desktop) {
                    @include font-size(16);
                }

            }

            &-desc {
                @include font-size(14);

                line-height: 1.8;
                color: rgba($GRY, 1);

                // モバイル
                @include mq(mobile, desktop) {
                    @include font-size(12);
                }
            }
        }
    }
}
