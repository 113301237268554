
/* Fullscreen  */

.fancybox-button--fullscreen::before {
	width: 15px;
	height: 11px;
	left: calc(50% - 7px);
	top: calc(50% - 6px);
	border: 2px solid;
	background: none;
}
