.page-top {
    .l-hero {
        width: 100%;
        height: calc(100vh - 100px);
        background: url("../img/top-hero_bg.jpg") no-repeat center (center / cover);
        position: relative;

        // モバイル
        @include mq(mobile, desktop) {
            height: 560px;
            background: url("../img/top-hero_bg-sp.jpg") no-repeat center (center / cover);
        }



        &__Trigger {
            position: absolute;
            top: 30px;
            right: 30px;
            // モバイル
            @include mq(mobile, desktop) {
                top: 16px;
                right: 16px;
            }
        }

    }

    .l-hero__Innr {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 60px 80px;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0.05) 100%);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 0 0;

        // モバイル
        @include mq(mobile, desktop) {
            padding: 30px 20px 30px;
        }


        // モバイル・ランドスケープ
        @include mq($until: tablet, $and: "(orientation: landscape)") {
            padding: 30px 20px 30px;
        }


        // タブレット
        @include mq(tablet, desktop) {
            padding: 60px 30px 60px;
        }
    }

    .l-hero__InnrLead {
        &-eng {
            margin: 0 0 32px;

            // モバイル
            @include mq(mobile, desktop) {
                margin: 0 30px 30px 0;
            }


            // モバイル・ランドスケープ
            @include mq($until: tablet, $and: "(orientation: landscape)") {
                margin: 0 0 20px;
            }


            // タブレット
            @include mq(tablet, desktop) {
                margin: 0 0 50px;
            }


            img {
                width: 500px;
                height: auto;

                // モバイル
                @include mq(mobile, desktop) {
                    width: 100%;
                    height: auto;
                }


                // モバイル・ランドスケープ
                @include mq($until: tablet, $and: "(orientation: landscape)") {
                    width: 44%;
                    height: auto;
                }


                // タブレット
                @include mq(tablet, desktop) {
                    width: 60%;
                    height: auto;
                }
            }
        }

        &-jpn {
            margin: 0;

            img {
                width: 405px;
                height: 36px;

                // モバイル
                @include mq(mobile, desktop) {
                    width: 93%;
                    height: auto;
                }


                // モバイル・ランドスケープ
                @include mq($until: tablet, $and: "(orientation: landscape)") {
                    width: 40%;
                    height: auto;
                }


                // タブレット
                @include mq(tablet, desktop) {
                    width: 50%;
                    height: auto;
                }
            }
        }
    }

    .l-hero__Badge {
        position: absolute;
        top: 50%;
        margin-top: -180px;
        right: 80px;
        width: 240px;
        height: 240px;
        z-index: 10;

        // モバイル
        @include mq(mobile, desktop) {
            width: 120px;
            height: 120px;
            right: auto;
            left: 20px;
            margin-top: -210px;
        }

        // モバイル・ランドスケープ
        @include mq($until: tablet, $and: "(orientation: landscape)") {
            width: 160px;
            height: 160px;
            top: 50%;
            margin-top: -60px;
            left: auto;
            right: 40px;
        }

        // タブレット
        @include mq(tablet, desktop) {
            width: 200px;
            height: 200px;
            right: 150px;
            margin-top: -240px;
        }


        &-link {
            background: transparent url("../img/badge.png") 0 0 (no-repeat / 240px) 240px;
            display: block;
            width: 240px;
            height: 240px;

            // モバイル
            @include mq(mobile, desktop) {
                background: transparent url("../img/badge.png") 0 0 (no-repeat / 120px) 120px;
                display: block;
                width: 120px;
                height: 120px;
            }


            // モバイル・ランドスケープ
            @include mq($until: tablet, $and: "(orientation: landscape)") {
                background: transparent url("../img/badge.png") 0 0 (no-repeat / 160px) 160px;
                display: block;
                width: 160px;
                height: 160px;
            }


            // タブレット
            @include mq(tablet, desktop) {
                right: 150px;
                background: transparent url("../img/badge.png") 0 0 (no-repeat / 200px) 200px;
                display: block;
                width: 200px;
                height: 200px;
            }
        }

        &:hover {
            opacity: .87;
        }
    }

    .l-hero__Logo {
        position: absolute;
        right: 110px;
        bottom: 110px;

        // モバイル
        @include mq(mobile, desktop) {
            left: 25px;
            right: auto;
            bottom: 210px;
        }


        // モバイル・ランドスケープ
        @include mq($until: tablet, $and: "(orientation: landscape)") {
            left: 25px;
            right: auto;
            bottom: 205px;
        }


        // タブレット
        @include mq(tablet, desktop) {
            left: 30px;
            right: auto;
            bottom: 300px;
        }


        img {
            width: 180px;
            height: auto;

            // モバイル
            @include mq(mobile, desktop) {
                width: 140px;
                height: auto;
            }


            // モバイル・ランドスケープ
            @include mq($until: tablet, $and: "(orientation: landscape)") {
                width: 150px;
                height: auto;
            }


            // タブレット
            @include mq(tablet, desktop) {
                width: 160px;
                height: auto;
            }
        }
    }

    .l-hero__LogoWs {
        position: absolute;
        left: 30px;
        top: 40px;
        margin: 0;

        // モバイル
        @include mq(mobile, desktop) {
            right: 50px;
            top: 15px;
        }


        // モバイル・ランドスケープ
        @include mq($until: tablet, $and: "(orientation: landscape)") {
            right: 10px;
            top: 15px;
        }


        // タブレット
        @include mq(tablet, desktop) {
            right: 30px;
            top: 30px;
        }


        img {
            width: auto;
            height: 22px;

            // モバイル
            @include mq(mobile, desktop) {
                height: 16px;
            }


            // モバイル・ランドスケープ
            @include mq($until: tablet, $and: "(orientation: landscape)") {
                height: 20px;
            }


            // タブレット
            @include mq(tablet, desktop) {
                height: 22px;
            }
        }
    }
}

.page-ojt,
.page-newEmployee {
    .l-hero {
        background: $GRN_LIGHTEST;
        height: 400px;
        overflow: hidden;
        padding: 0;
        position: relative;
        width: calc(100%);

        // モバイル
        @include mq(mobile, desktop) {
            height: 240px;
        }


        // モバイル・ランドスケープ
        @include mq($until: tablet, $and: "(orientation: landscape)") {
            height: 400px;
        }


        // タブレット
        @include mq(tablet, desktop) {
            height: 480px;
        }

        &.is-newEmployee {
            background: url("../img/service-HeroBg_1.jpg") no-repeat center (center / cover);
        }

        &.is-ojt {
            background: url("../img/service-HeroBg_2.jpg") no-repeat center (center / cover);
        }
    }

    .l-heroContents {
        // .l-heroContents__Movie
        display: flex;
        align-items: center;
        justify-content: center;
        height: 400px;

        // モバイル
        @include mq(mobile, desktop) {
            height: 240px;
        }


        // モバイル・ランドスケープ
        @include mq($until: tablet, $and: "(orientation: landscape)") {
            height: 400px;
        }


        // タブレット
        @include mq(tablet, desktop) {
            height: 480px;
        }


        &__Movie {
            margin: 23px 0 3px;

            // background-image: linear-gradient(45deg, rgba(110, 230, 196, 1) 0%, rgba(254, 219, 142, 1) 100%);
            // .l-heroContents__Movie-link

            &-link {
                @include font-size(15);

                align-items: center;
                background-color: rgba($WHT, 1);
                border-radius: 6px;
                color: rgba($GRN, 1);
                display: flex;
                font-weight: bold;
                justify-content: center;
                padding: 16px 0;
                margin: 0 auto;
                width: 240px;
                text-align: center;
                text-decoration: none;
                font-family: inherit;

                &:hover {
                    background-color: rgba($WHT, 0.7);
                }

                .ss-video {
                    margin-right: 10px;
                    padding-top: 10px;

                    &:before {
                        @include font-size(18);
                    }
                }

                // モバイル
                @include mq(mobile, desktop) {
                    @include font-size(14);

                    padding: 16px 0;
                }


                // モバイル・ランドスケープ
                @include mq($until: tablet, $and: "(orientation: landscape)") {
                    @include font-size(14);

                    padding: 20px 0;
                }


                // タブレット
                @include mq(tablet, desktop) {
                    @include font-size(14);

                    padding: 20px 0;
                }
            }
        }

        &__Lead {
            text-align: center;
            z-index: 5;

            // モバイル
            @include mq(mobile, desktop) {}


            // モバイル・ランドスケープ
            @include mq($until: tablet, $and: "(orientation: landscape)") {}


            // タブレット
            @include mq(tablet, desktop) {}


            &-name {
                img {
                    width: auto;
                    height: 48px;

                    // モバイル
                    @include mq(mobile, desktop) {
                        height: 24px;
                    }

                    // モバイル・ランドスケープ
                    @include mq($until: tablet, $and: "(orientation: landscape)") {
                        height: 30px;
                    }

                    // タブレット
                    @include mq(tablet, desktop) {
                        height: 36px;
                    }
                }
            }

            &-genre {
                width: 240px;
                padding: 12px 0 16px;
                background-color: rgba($WHT, 1);
                border-radius: 48px;
                margin: 0 auto 40px;

                // モバイル
                @include mq(mobile, desktop) {
                    padding: 6px 0 12px;
                    margin: 0 auto 20px;
                    width: 160px;
                }


                // モバイル・ランドスケープ
                @include mq($until: tablet, $and: "(orientation: landscape)") {
                    padding: 6px 0 12px;
                    margin: 0 auto 30px;
                }


                // タブレット
                @include mq(tablet, desktop) {
                    padding: 12px 0 16px;
                    margin: 0 auto 40px;
                }

                img {
                    width: auto;
                    height: 21px;

                    // モバイル
                    @include mq(mobile, desktop) {
                        height: 16px;
                    }
                    // // モバイル・ランドスケープ
                    // @include mq($until: tablet, $and: "(orientation: landscape)") {
                    //     height: 16px;
                    // }
                    // // タブレット
                    // @include mq(tablet, desktop) {
                    //     height: 20px;
                    // }
                }
            }

            &-heading {
                @include font-size(24);

                line-height: 1.4;
                color: rgba($GRN, 1);
                margin: 30px 0 20px;

                // モバイル
                @include mq(mobile, desktop) {
                    @include font-size(16);

                    margin: 15px 0 10px;
                }


                // モバイル・ランドスケープ
                @include mq($until: tablet, $and: "(orientation: landscape)") {
                    @include font-size(16);

                    margin: 15px 0 10px;
                }


                // タブレット
                @include mq(tablet, desktop) {
                    @include font-size(24);

                    margin: 30px 0 20px;
                }
            }

            &-body {
                @extend %font-16_grn;

                font-weight: bold;
                margin: 20px 0 0;
                color: rgba($WHT, 1);

                // モバイル
                @include mq(mobile, desktop) {
                    @include font-size(13);
                    line-height: 1.6;
                    margin: 10px 0;
                }


                // モバイル・ランドスケープ
                @include mq($until: tablet, $and: "(orientation: landscape)") {
                    @include font-size(14);
                    line-height: 1.6;

                    margin: 10px 0;
                }


                // タブレット
                @include mq(tablet, desktop) {
                    @include font-size(15);
                    line-height: 1.6;
                    margin: 20px 0;
                }
            }
        }
    }

    .l-heroContentsPhoto {
        height: 930px;
        left: 35%;
        position: absolute;
        top: 60px;
        width: 936px;
        z-index: 4;
        margin-left: auto;

        // モバイル
        @include mq(mobile, desktop) {
            bottom: -85px;
            left: 50%;
            margin-left: -160px;
            position: absolute;
            top: auto;
            width: 320px;
            height: auto;
            z-index: 4;
        }


        // モバイル・ランドスケープ
        @include mq($until: tablet, $and: "(orientation: landscape)") {
            bottom: -300px;
            left: 50%;
            margin-left: -300px;
            position: absolute;
            top: auto;
            width: 600px;
            height: auto;
            z-index: 4;
        }


        // タブレット
        @include mq(tablet, desktop) {
            bottom: -270px;
            left: 50%;
            margin-left: -320px;
            position: absolute;
            top: auto;
            width: 640px;
            height: auto;
            z-index: 4;
        }


        img {
            display: block;
            height: 930px;
            width: 936px;

            // モバイル
            @include mq(mobile, desktop) {
                width: 100%;
                height: auto;
            }


            // モバイル・ランドスケープ
            @include mq($until: tablet, $and: "(orientation: landscape)") {
                width: 100%;
                height: auto;
            }


            // タブレット
            @include mq(tablet, desktop) {
                width: 100%;
                height: auto;
            }
        }
    }
}
