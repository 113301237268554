.page-top {
    .l-case {
        padding-top: 80px;

        @include mq($from: mobile, $until:tablet) {
            padding: 60px 20px 0;
        }
    }

}
