div#loader{
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;
  text-align: center;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
}
div#loader div{
  width: 200px;
  margin-left: -100px;
  margin-top: -100px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
}


#loader-bar {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 6px solid #ab8c1f;
  border-right-color: transparent;
  -webkit-animation: loader 1s infinite linear;
  -moz-animation: loader 1s infinite linear;
  -ms-animation: loader 1s infinite linear;
  animation: loader 1s infinite linear
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    opacity: 1
  }

  50% {
    -webkit-transform: rotate(180deg);
    opacity: 1
  }

  100% {
    -webkit-transform: rotate(360deg);
    opacity: 1
  }
}

@-moz-keyframes loader {
  0% {
    -moz-transform: rotate(0deg);
    opacity: 1
  }

  50% {
    -moz-transform: rotate(180deg);
    opacity: 1
  }

  100% {
    -moz-transform: rotate(360deg);
    opacity: 1
  }
}

@-ms-keyframes loader {
  0% {
    -ms-transform: rotate(0deg);
    opacity: 1
  }

  50% {
    -ms-transform: rotate(180deg);
    opacity: 1
  }

  100% {
    -ms-transform: rotate(360deg);
    opacity: 1
  }
}

@-ms-keyframes loader {
  0% {
    transform: rotate(0deg);
    opacity: 1
  }

  50% {
    transform: rotate(180deg);
    opacity: 1
  }

  100% {
    transform: rotate(360deg);
    opacity: 1
  }
}
