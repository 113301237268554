//セレクトボックス用装飾
.c-input {
    padding: 10px 15px 11px;
    @include font-size(16);
    border: 1px solid $GRY_LIGHTER;
    border-radius: 3px; // width: auto;
    &:hover {
        border: 1px solid $GRY_LIGHT;
    }
    @include placeholderColor($GRY_LIGHT);
    @include mq($from: mobile,
    $until:desktop) {
        @include font-size(16);
        padding: 6px 12px 7px;
    }
}

.c-textarea {
    padding: 10px 15px 11px;
    @include font-size(16);
    line-height: 1.8;
    border: 1px solid $GRY_LIGHTER;
    border-radius: 3px;
    min-height: 180px;
    outline: none;

    &:hover {
        border: 1px solid $GRY_LIGHT;
    }
    @include mq($from: mobile,
    $until:desktop) {
        @include font-size(16);
    }
}

.c-select {
    background-color: $WHT;
    background-image: url('../img/selector.svg');
    background-size: 8px 15px;
    background-position: right 8px top 13px;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    line-height: 1;
    font-weight: bold;
    display: inline-block;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border: 1px solid $GRY_LIGHTER;
    border-radius: 3px;
    box-shadow: none;
    color: $BLK;
    @include font_size(16);
    padding: 16px 24px 16px 16px;
    line-height: 1.1;
    &:hover {
        border: 1px solid $GRY_LIGHT;
    }
    &::-ms-expand {
        display: none;
    }
}

.c-fileup {
    border: 1px solid rgba($GRY_LIGHTER, .4);
    display: block;
    background-color: rgba($GRY, .2);
    padding: 12px;
    border-radius: 4px;
    @include font-size(16);
    font-weight: bold;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    cursor: pointer;
    align-items: center;
    transition: 0.2s ease-out;

    &:hover {
        border: 1px solid rgba($GRY, 1);
    }
    .custom-upload__txt {}
    #file_name {
        padding-left: 20px;
        font-weight: bold;
    }
}

.c-checkbox {
    @include custom-input(30px,checkbox);
}

.c-radio {
    @include custom-input(30px,radio);
}

.c-require {
    position: relative;
    &::before {
        position: absolute;
        top: -6px;
        right: -14px;
        content: "*";
        @include font-size(24);
        color: $HOV;
    }
}
