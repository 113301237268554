.l-menu {
    background-color: rgba($GRN, 1);
    height: 100%;
    display: none;

    .js-hiraku-offcanvas-body-right & {
        display: block;
    }

    &__Heading {
        text-align: center;
        padding: 20px;
        width: 100%;

        h2 {
            img {
                width: width("logo-wht@2x.png", 6);
                height: height("logo-wht@2x.png", 6);
            }
        }
    }

    &__Body {
        &-wrap {
            border-top: 1px solid rgba($WHT, .5);
        }

        &-item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            border-bottom: 1px solid rgba($WHT, .5);


            .fa-angle-right {
                color: rgba($WHT, 1);
                margin-right: 8px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            a {
                background-color: rgba($BLK, 0);
                display: block;
                width: 100%;
                height: 100%;
                padding: 16px;
                @extend .f-jp_b;
                color: rgba($WHT, 1);
                @include font-size(14);
                text-decoration: none;

                &:hover {
                    background-color: rgba($BLK, .2);
                }
            }
        }
    }

    &__Bottom {
        text-align: center;
        padding: 30px 20px;
        width: 100%;

        &-logoWS {
            img {
                width: auto;
                height: height("ws_logo-wht@2x.png", 2);
            }
        }
    }

    &__Close {
        &-link {
            color: rgba($WHT, 1);
            @include font-size(16)
            text-align: center;
        }

    }
}
