.page-top {
    .l-serviceContentsPhoto {
        height: 930px;
        left: 35%;
        position: absolute;
        top: 164px;
        width: 936px;
        z-index: 4;
        margin-left: auto;

        // モバイル
        @include mq(mobile, desktop) {
            bottom: -55px;
            left: 50%;
            margin-left: -160px;
            position: absolute;
            top: auto;
            width: 320px;
            height: auto;
            z-index: 4;
        }

        img {
            display: block;
            height: 930px;
            width: 936px;

            // モバイル
            @include mq(mobile, desktop) {
                width: 100%;
                height: auto;
            }

        }
    }
}
