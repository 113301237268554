.page-ojt {
    .l-aboutGraph {
        display: flex;
        align-items: center;
        justify-content: center;

        // モバイル
        @include mq(mobile, desktop) {
            display: block;
        }


        // モバイル・ランドスケープ
        @include mq($until: tablet, $and: "(orientation: landscape)") {
            display: block;
        }


        // タブレット
        @include mq(tablet, desktop) {
            display: block;
        }


        &__text {
            width: calc(100% - 795px);
            min-width: 390px;
            text-align: center;

            @include font-size(16);

            line-height: 1.8;

            // モバイル
            @include mq(mobile, desktop) {
                width: calc(100%);
                min-width: auto;
                padding: 0 20px !important;
                margin-bottom: 6px;
                @include font-size(14);
            }


            // モバイル・ランドスケープ
            @include mq($until: tablet, $and: "(orientation: landscape)") {
                width: calc(100%);
                min-width: auto;
                padding: 0 30px !important;
                margin-bottom: 20px;
            }


            // タブレット
            @include mq(tablet, desktop) {
                width: calc(100%);
                min-width: auto;
                padding: 0 40px !important;
                margin-bottom: 20px;
            }


            h3 {
                img {
                    width: auto;
                    margin-bottom: 24px;

                    // モバイル
                    @include mq(mobile, desktop) {
                        width: auto;
                        min-width: auto;
                        margin-bottom: 12px;
                    }


                    // モバイル・ランドスケープ
                    @include mq($until: tablet, $and: "(orientation: landscape)") {
                        width: auto;
                        min-width: auto;
                        margin-bottom: 18px;
                    }


                    // タブレット
                    @include mq(tablet, desktop) {
                        width: auto;
                        min-width: auto;
                        margin-bottom: 24px;
                    }
                }

                &.l-aboutGraph__text-first {
                    img {
                        height: 38px;

                        // モバイル
                        @include mq(mobile, desktop) {
                            height: 24px;
                        }

                        // モバイル・ランドスケープ
                        @include mq($until: tablet, $and: "(orientation: landscape)") {
                            height: 30px;
                        }

                        // タブレット
                        @include mq(tablet, desktop) {
                            height: 34px;
                        }
                    }
                }

                &.l-aboutGraph__text-second {
                    img {
                        height: 38px;

                        // モバイル
                        @include mq(mobile, desktop) {
                            height: 24px;
                        }

                        // モバイル・ランドスケープ
                        @include mq($until: tablet, $and: "(orientation: landscape)") {
                            height: 30px;
                        }

                        // タブレット
                        @include mq(tablet, desktop) {
                            height: 34px;
                        }
                    }
                }
            }
        }

        &__body {
            width: 795px;
            height: 400px;

            // モバイル
            @include mq(mobile, desktop) {
                width: calc(100%);
                height: auto;
                padding: 0 20px;
            }


            // モバイル・ランドスケープ
            @include mq($until: tablet, $and: "(orientation: landscape)") {
                width: calc(100%);
                height: auto;
                padding: 0 20px;
            }


            // タブレット
            @include mq(tablet, desktop) {
                width: calc(100%);
                height: auto;
                padding: 0 20px;
            }
        }
    }
}
