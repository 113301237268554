.page-ojt {
    .l-supportMenuItem {
        background-color: rgba($WHT, 1);
        margin: 0 auto 10px;
        border-radius: 8px;
        border: 1px solid rgba($GRY_LIGHTEST, 1);

        // モバイル
        @include mq(mobile, desktop) {
            width: 100%;
            margin: 0 auto 5px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &__Innr {
            display: flex;
            align-items: center;
            justify-content: start;
            position: relative;
            border-radius: 8px;

            // モバイル
            @include mq(mobile, desktop) {
            }

            &-wrapBottom {
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                align-items: center;
                text-align: center;

                @include mq(mobile, desktop) {
                    display: block;
                    padding: 8px 20px;
                }

            }

            &-wrap {
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgba($BLK, 1);
                flex: 0 0 180px;
                background-color: rgba($YLW, 1);
                border-radius: 8px 0 0 8px;
                position: relative;
                margin-right: 40px;

                // モバイル
                @include mq(mobile, desktop) {
                    margin-right: 0;
                    display: block;
                    text-align: center;
                    flex: 0 0 80px;
                }

                &:before {
                    content: "";
                    position: absolute;
                    z-index: 10;
                    top: -2px;
                    right: -15px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 32px 0 32px 16px;
                    border-radius: 8px 0 0 8px;
                    border-color: transparent transparent transparent $YLW;

                    // モバイル
                    @include mq(mobile, desktop) {
                        content: none;
                    }
                }
            }


            &-icon {
                padding: 12px 0;
                position: relative;

                img {
                    height: 36px;
                    width: 36px;
                }

                // モバイル
                @include mq(mobile, desktop) {
                    background-color: rgba($YLW, 1);
                    border-radius: 8px 8px 0 0;
                    padding: 8px 0;
                }

            }

            &-month {
                flex: 0 0 88px;
                @include font-size(14);
                border-left: 1px solid rgba($WHT, 1);
                color: rgba($WHT, 1);
                font-weight: bold;
                margin-left: 20px;
                padding-left: 20px;

                // モバイル
                @include mq(mobile, desktop) {
                    border-left: none;
                    @include font-size(12);
                    text-align: center;
                    padding: 0 0 12px 0;
                    margin-left: 0;
                    width: 100%;
                }
            }

            &-title {
                @include font-size(20);

                flex: 0 0 280px;
                color: rgba($GRY, 1);
                font-weight: bold;
                text-align: left;

                // モバイル
                @include mq(mobile, desktop) {
                    @include font-size(16);
                    text-align: center;
                    padding-bottom: 6px;
                }
            }

            &-desc {
                @include font-size(14);
                flex: 1 0 auto;
                color: rgba($GRY, 1);
                text-align: left;

                // モバイル
                @include mq(mobile, desktop) {
                    @include font-size(12);
                    padding: 0;
                    text-align: center;
                }

            }
        }
    }
}
