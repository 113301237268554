.page-ojt {
    .l-featureItem {
        text-align: center;
        padding: 44px 0 40px;

        // モバイル
        @include mq(mobile, desktop) {
            padding: 20px 0px;
        }

        &__Main {
            img {
                width: auto;
                height: 30px;

                // モバイル
                @include mq(mobile, desktop) {
                    height: 24px;
                }

            }
        }

        &__Sub {
            @include font-size(18);

            margin: 16px 0 10px;
            color: rgba($GRN, 1);

            // モバイル
            @include mq(mobile, desktop) {
                @include font-size(16);

                margin: 8px 0 10px;
            }

        }

        &__Body {
            @include font-size(14);

            line-height: 1.8;

            // モバイル
            @include mq(mobile, desktop) {
                @include font-size(13);
            }

        }
    }

}
