.page-function {
    .l-tabBody {
        background-color: rgba($GRN_LL, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 64px;
        padding: 16px;
        margin-top: 96px;

        @include mq(mobile, desktop) {
            margin: 48px auto 0;
            padding: 8px;
            max-width: 280px;
        }

        &__Switch {
            position: relative;

            &-caption {
                position: absolute;
                @include font-size(12);
                top: -12px;
                left: calc(50% - 12px);
                color: rgba($GRN, 1);
                @extend .f-en_b;

                @include mq(mobile, desktop) {
                    display: none;
                }
            }
        }

        &__Item {
            &-link {
                width: 230px;
                padding: 16px;
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 64px;
                @extend .f-en_b;

                @include mq(mobile, desktop) {
                    width: 102px;
                    padding: 8px;
                }

                &.is-active {
                    background-color: rgba($GRN, 1);

                    .far {
                        color: rgba($WHT, 1);
                    }

                    h6 {
                        color: rgba($WHT, 1);
                    }

                    &:hover {
                        background-color: rgba($GRN, 1);

                        .far {
                            color: rgba($WHT, 1);
                        }

                        h6 {
                            color: rgba($WHT, 1);
                        }
                    }
                }

                &:hover {
                    background-color: rgba($WHT, 1);
                    color: rgba($RED, 1);
                    text-decoration: none;

                    .far {
                        color: rgba($RED, 1);
                    }

                    h6 {
                        color: rgba($RED, 1);
                    }
                }
            }

            .far {
                color: rgba($GRN, 1);

                @include mq(mobile, desktop) {
                    margin-top: -2px;
                }
                &:before {
                    @include font-size(24);
                    @include mq(mobile, desktop) {
                        @include font-size(12);
                    }
                }
            }

            h6 {
                @include font-size(16);
                margin-left: 12px;
                color: rgba($GRN, 1);

                @include mq(mobile, desktop) {
                    @include font-size(11);
                    margin-left: 4px;
                }
            }
        }
    }

    .tabs-pane {
        display: none;

        &.is-active {
            display: block;
        }
    }

    .c-switch {
        position: relative;
        display: inline-block;
        width: 72px;
        height: 8px;
        margin: 24px 24px 0;

        @include mq(mobile, desktop) {
            width: 32px;
            margin: 12px 12px 0;
        }

        input {
            display: none;
        }

        &-slider {
            border-radius: 30px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            color: $YLW;
            background-color: $YLW;
            -webkit-transition: .1s;
            transition: .1s;
            @extend %blurbox_inset;

            &:before {
                content: "";
                position: absolute;
                @include font-size(11);
                font-weight: bold;
                border-radius: 30px;
                position: absolute;
                line-height: 30px;
                text-align: center;
                height: 30px;
                width: 30px;
                left: 0px;
                bottom: -11px;
                background-color: $GRN;
                -webkit-transition: .6s;
                transition: .6s;

                @include mq(mobile, desktop) {
                    border-radius: 18px;
                    line-height: 18px;
                    height: 18px;
                    width: 18px;
                    bottom: -5px;
                }
            }
        }

        &.is-activeJinji {
            .c-switch-slider:before {
                -webkit-transform: translateX(42px);
                -ms-transform: translateX(42px);
                transform: translateX(42px);

                @include mq(mobile, desktop) {
                    -webkit-transform: translateX(14px);
                    -ms-transform: translateX(14px);
                    transform: translateX(14px);
                }
            }
        }

        &.is-activeUser {
            .c-switch-slider:before {
                -webkit-transform: translateX(0px);
                -ms-transform: translateX(0px);
                transform: translateX(0px);
            }
        }
    }

    input:checked+.c-switch-slider {
        background-color: $YLW;
        color: $YLW;
    }

    input:focus+.c-switch-slider {
        box-shadow: 0 0 1px $BLK;
    }

    input:checked+.c-switch-slider:before {
        -webkit-transform: translateX(42px);
        -ms-transform: translateX(42px);
        transform: translateX(42px);
    }

    .c-switch-label {
        @include font-size(12);
        margin-right: 10px;
    }
}
