.page-function {
    .l-subnav {
        margin-top: 96px;
        width: 100%;
        background-color: rgba($GRN_LLL, 1);
        padding: 20px 20px 0 20px;

        // モバイル
        @include mq(mobile, desktop) {
            margin-top: 64px;
            padding: 10px 10px 0 10px;
        }

        &__Innr {
            display: flex;
            align-items: center;
            justify-content: center;

            &-item {
                flex: 0 0 50%;
                &:first-child{
                    border-right: 10px solid rgba($GRN_LLL, 1);

                    // モバイル
                    @include mq(mobile, desktop) {
                        border-right: 5px solid rgba($GRN_LLL, 1);
                    }
                }
                &:last-child{
                    border-left: 10px solid rgba($GRN_LLL, 1);

                    // モバイル
                    @include mq(mobile, desktop) {
                        border-left: 5px solid rgba($GRN_LLL, 1);
                    }
                }
                a{
                    background-color: rgba($WHT, 1);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 96px;
                    color: rgba($GRN, 1);
                    @include font-size(24);
                    @extend %f-jp_b;
                    text-decoration: none;

                    // モバイル
                    @include mq(mobile, desktop) {
                        height: auto;
                        padding: 8px 0;
                        display: block;
                        text-align: center;
                        @include font-size(15);
                        margin-bottom: -16px;
                    }

                    &:hover{
                        background-color: rgba($HOV, 1);
                        color: rgba($WHT, 1);
                        text-decoration: none;
                    }

                    &.is-active{
                        background-color: #C1E4DF;
                        color: rgba($WHT, 1);
                        pointer-events: none;

                        // モバイル
                        @include mq(mobile, desktop) {
                            padding: 16px 0;
                            margin-bottom: 0;
                        }

                    }
                    i{
                        @include font-size(32);
                        margin-right: 16px;

                        // モバイル
                        @include mq(mobile, desktop) {
                            @include font-size(22);
                        }
                    }

                }
            }
        }
    }
}
