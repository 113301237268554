.c-dotNav {
    position: fixed;
    top: 50%;
    right: 10px;
    z-index: 10000;
    transform: translate(-50%, -50%);
/*
    @include mq(mobile, desktop) {
        display: none;
    }*/

    &-item {
        width: 11px;
        height: 11px;
        display: block;
        margin: 0 0 14px;
        background: rgba($GRY, 0.1);
        border-radius: 50%;
        cursor: pointer;
        transition: background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

        &:last-child {
            margin: 0;
        }
        &.is-active {
            background: rgba($GRN, 1);
            transition: background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
        }
    }
}
