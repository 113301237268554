.page-top {
    .l-caseContents {
        width: 960px;
        margin: 0 auto;

        @include mq($from: mobile, $until:tablet) {
            width: 100%;
        }

        &__List {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 20px;

            @include mq($from: mobile, $until:tablet) {
                width: 100%;
                flex-wrap: wrap;
            }

            &-item {
                background-color: $GRY_LIGHTEST;
                width: 160px;
                height: 90px;
                margin-right: 40px;

                @include mq($from: mobile, $until:tablet) {
                    width: 80px;
                    height: 45px;
                    margin-right: 20px;
                }

                img {
                    width: 160px;
                    height: 90px;

                    @include mq($from: mobile, $until:tablet) {
                        width: 80px;
                        height: 45px;
                    }
                }

                // margin-bottom: 40px;
                &:nth-child(5n) {
                    margin-right: 0;

                    @include mq($from: mobile, $until:tablet) {
                        margin-right: 20px;
                    }
                }

                &:nth-child(3n) {
                    @include mq($from: mobile, $until:tablet) {
                        margin-right: 0px;
                    }
                }

                &:nth-child(6n) {
                    @include mq($from: mobile, $until:tablet) {
                        margin-right: 0px;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &__Desc {
            @include font-size(14);
            line-height: 1.8;
            text-align: center;
            color: rgba($GRY, 1);
            margin-top: 20px;

            strong {
                font-weight: bold;
                color: rgba($GRN, 1);
                @include font-size(24);
                margin: 0 6px;
            }
        }

        &__Caption {
            @include font-size(12);
            text-align: center;
            color: rgba($GRY, 1);
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}

