.page-newEmployee {

    .l-featureContents {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: stretch;
        justify-content: center;
        margin-top: 40px;

        // モバイル
        @include mq(mobile, desktop) {
            flex-wrap: wrap;
            margin-top: 20px;
        }

    }
}
