.page-function {
    .l-cover {
        background-image: linear-gradient(0deg, rgba(197, 213, 232, 1) 0%, rgba(193, 228, 223, 1) 100%);
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: block;
        overflow: hidden;
        position: relative;

        @include mq(mobile, desktop) {
            padding: 32px 0;
        }

        &.is-coverUser {
            /*height: 720px;*/
            height: 480px;


            @include mq(mobile, desktop) {
                height: auto;
            }

            .l-cover__Innr-leadMark {
                img {
                    width: auto;
                    height: height("iconService_00A.png", 3);

                    @include mq(mobile, desktop) {
                        width: auto;
                        height: height("iconService_00A.png", 5);
                    }
                }
            }

            .l-cover__Innr-leadMain {
                img {
                    width: auto;
                    height: height("functionServiceLead.png", 2);

                    @include mq(mobile, desktop) {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        &.is-coverJinji {
            height: 480px;

            @include mq(mobile, desktop) {
                height: auto;
            }

            .l-cover__Innr-leadMark {
                img {
                    width: auto;
                    height: height("iconService_00B.png", 2);

                    @include mq(mobile, desktop) {
                        width: auto;
                        height: height("iconService_00B.png", 5);
                    }
                }
            }

            .l-cover__Innr-leadMain {
                img {
                    width: auto;
                    height: height("functionManageLead.png", 2);

                    @include mq(mobile, desktop) {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        &::before {
            content: "";
            z-index: 2;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url(../img/coverInnrShadow.svg) no-repeat 0 0;
            /*フレーム下部画像*/
            background-size: 100% 100%;
            opacity: .5;

            @include mq(mobile, desktop) {
                content: none;
            }

        }

        &__Innr {
            position: relative;
            z-index: 3;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            @include mq(mobile, desktop) {
                padding: 0 20px;
            }

            &-leadMain {
                margin: 32px 0;

                @include mq(mobile, desktop) {
                    margin: 16px 0;
                    padding: 0 20px;
                }

                img {
                    &.is-pc {
                        display: block;

                        @include mq(mobile, desktop) {
                            display: none;
                        }
                    }

                    &.is-sp {
                        display: none;

                        @include mq(mobile, desktop) {
                            display: block;
                        }
                    }
                }
            }

            &-lead {
                @include font-size(22);
                line-height: 2.2;
                @extend .f-en_b;
                color: rgba($WHT, 1);

                @include mq(mobile, desktop) {
                    padding: 0 20px;
                    @include font-size(13);
                    line-height: 1.6;
                }
            }

            &-trigger {
                width: 722px;
                margin: 32px auto 0;

                @include mq(mobile, desktop) {
                    display: block;
                    width: 100%;
                    margin: 16px auto 0;
                }
            }
            &-wrapItem {
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                align-items: stretch;
                justify-content: center;
            }
        }
    }
}
