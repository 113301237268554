.page-top {
    .l-conceptContents {
        &__Desc {
            @extend %f-jp_b;
            text-align: center;
            margin: 30px 0 40px;
            line-height: 2;

            // モバイル
            @include mq(mobile, desktop) {
                @include font-size(14);
                line-height: 1.75;
                color: rgba($GRN, 1);
                margin: 20px 0 20px;
            }


            // モバイル・ランドスケープ
            @include mq($until: tablet, $and: "(orientation: landscape)") {}


            // タブレット
            @include mq(tablet, desktop) {}
        }

        &__Feature {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: flex-start;
            justify-content: center;

            // モバイル
            @include mq(mobile, desktop) {
                flex-wrap: wrap;
                height: 350px;
                position: relative;
            }


            // モバイル・ランドスケープ
            @include mq($until: tablet, $and: "(orientation: landscape)") {
                flex-wrap: wrap;
            }


            // タブレット
            @include mq(tablet, desktop) {
                flex-wrap: nowrap;
                align-items: stretch;
            }


            &-point {
                @extend %font-16_grn;
                @extend %f-jp_b;

                background-color: rgba($WHT, 1);
                border-radius: 12px;
                font-weight: bold;
                line-height: 2.75;
                margin-top: 58px;
                min-width: 376px;
                padding: 32px 44px;

                // モバイル
                @include mq(mobile, desktop) {
                    text-align: center;

                    @include font-size(13);

                    color: rgba($GRN, 1);
                    line-height: 2;
                    margin-top: 0px;
                    width: 100%;
                    min-width: auto;
                    padding: 20px;
                    position: absolute;
                    top: 190px;
                    left: 0;
                }


                // モバイル・ランドスケープ
                @include mq($until: tablet, $and: "(orientation: landscape)") {
                    @include font-size(14);

                    width: 100%;
                    min-width: auto;
                    padding: 20px;
                }


                // タブレット
                @include mq(tablet, desktop) {
                    width: 50%;

                    @include font-size(16);

                    margin-right: 10px;
                    padding: 48px 0 0 0;
                }
            }

            &-image {
                width: 476px;
                min-width: 354px;
                text-align: center;
                position: relative;
                z-index: 10;

                // モバイル
                @include mq(mobile, desktop) {
                    width: calc(50%);
                    margin-left: -25%;
                    min-width: auto;
                    position: absolute;
                    bottom: -250px;
                    left: 50%;
                    z-index: 30;
                }


                // モバイル・ランドスケープ
                @include mq($until: tablet, $and: "(orientation: landscape)") {
                    width: calc(32%);
                    margin-left: -16%;
                }


                // タブレット
                @include mq(tablet, desktop) {
                    width: calc(40%);
                    margin-left: -20%;
                }


                img {
                    height: 536px;
                    width: 354px;

                    // モバイル
                    @include mq(mobile, desktop) {
                        height: auto;
                        width: 100%;
                    }


                    // モバイル・ランドスケープ
                    @include mq($until: tablet, $and: "(orientation: landscape)") {
                        height: auto;
                        width: 100%;
                    }


                    // タブレット
                    @include mq(tablet, desktop) {
                        height: auto;
                        width: 100%;
                    }
                }
            }

            &-logo {
                text-align: center;
                margin-top: 58px;
                background-color: rgba($WHT, 1);
                border-radius: 12px;
                padding: 32px 44px;
                width: 376px;
                min-width: 210px;
                position: relative;

                // モバイル
                @include mq(mobile, desktop) {
                    width: 100%;
                    min-width: auto;
                    padding: 30px 20px;
                    margin-top: 10px;
                    z-index: 20;
                    position: absolute;
                    top: 0;
                    left: 0;
                }


                // モバイル・ランドスケープ
                @include mq($until: tablet, $and: "(orientation: landscape)") {
                    width: 100%;
                    min-width: auto;
                    padding: 30px 20px;
                    margin-top: 10px;
                    z-index: 20;
                }


                // タブレット
                @include mq(tablet, desktop) {
                    margin-top: 0;
                    margin-left: 10px;
                    width: 50%;
                }


                h5 {
                    @include font-size(16);
                    color: rgba($GRY, 1);

                    line-height: 1;
                    font-weight: bold;

                    @include mq($from: mobile, $until: tablet) {
                        @include font-size(14);
                    }
                }

                img {
                    width: auto;
                    height: 80px;
                    margin: 32px 0 20px;

                    @include mq($from: mobile, $until: tablet) {
                        width: auto;
                        height: 48px;
                        margin: 12px 0 10px;
                    }
                }

                h6 {
                    color: rgba($GRY, 1);

                    @include font-size(16);

                    line-height: 1;
                    font-weight: bold;
                }

                &::before {
                    background: url("../img/icon-equals@2x.png") no-repeat 0 0 / 20px 13px;
                    content: "";
                    height: 13px;
                    left: -10px;
                    position: absolute;
                    top: calc(50% - 7px);
                    width: 20px;
                    z-index: 10;

                    @include mq(mobile, desktop) {
                        content: none;
                    }


                    // モバイル・ランドスケープ
                    @include mq($until: tablet, $and: "(orientation: landscape)") {
                        content: none;
                    }


                    // タブレット
                    @include mq(tablet, desktop) {
                        content: none;
                    }
                }
            }
        }
    }
}
