.page-newEmployee {
    .l-voiceContents {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        margin: 40px 0 120px;
        padding: 0 20px;

        // モバイル
        @include mq(mobile, desktop) {
            display: block;
            padding: 0 10px;
            margin: 20px 0 80px;
        }

        &__Item {
            width: 33.333%;
            color: rgba($GRN, 1);
            position: relative;
            @include font-size(15);
            line-height: 1.8;
            background: url("../img/icon-quote.svg") no-repeat top 16px left 16px, url("../img/icon-unquote.svg") no-repeat top 16px right 16px;
            background-size: 16px 16px;
            background-color: rgba($WHT, 1);
            padding: 24px;
            text-align: center;
            margin-bottom: 2px;

            // モバイル
            @include mq(mobile, desktop) {
                @include font-size(13);
                line-height: 1.5;
                background: url("../img/icon-quote.svg") no-repeat top 32px left 10px, url("../img/icon-unquote.svg") no-repeat top 32px right 10px;
                background-size: 10px 10px;
                background-color: rgba($WHT, 1);
                padding: 10px;
                width: 100%;
            }


            &:nth-child(2),
            &:nth-child(5) {
                width: calc(33.333% - 4px);
                margin-right: 2px;
                margin-left: 2px;

                // モバイル
                @include mq(mobile, desktop) {
                    width: 100%;
                    margin-right: 0px;
                    margin-left: 0px;
                }
            }

            &::before {
                position: absolute;
            }
        }
    }

}
