.c-footer {
    padding: 35px 20px;

    &__Innr {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mq($from: mobile, $until:desktop) {
            display: block;
            text-align: center;
        }
    }

    &__Logo {

        // モバイル
        @include mq(mobile, desktop) {
            margin-bottom: 20px;
        }

        // // モバイル・ランドスケープ
        // @include mq($until: tablet, $and: '(orientation: landscape)'){
        // }
        // // タブレット
        // @include mq(tablet,desktop) {
        // }

        img {
            width: 97px;
            height: 20px;

            @include mq(mobile, desktop) {
                width: 86px;
                height: 18px;
            }
        }

    }

    &__Copy {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;

        @include mq($from: mobile, $until:desktop) {
            display: block;
            margin-top: 6px;
        }

        &-item {
            @extend %f-en_b;
            color: rgba($GRY, 1);
            @include font-size(12);
            line-height: 1.1;
        }

        &-top {
            @extend %f-en_b;
            margin-left: 24px;
            @include mq($from: mobile, $until:desktop) {
                margin-left: 0px;
                margin-top: 24px;
            }
            a{
                color: rgba($GRN, 1);
                i{
                    @include font-size(32);

                }
            }
        }
    }

    &__Link {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;

        @include mq($from: mobile, $until:desktop) {
            display: block;
            margin-bottom: 16px;
        }

        &-item {
            margin-right: 30px;
            a{
                @extend .f-en_b;
                @include font-size(12);
                color: rgba($GRY, 1);
                line-height: 1.1;
            }
            @include mq($from: mobile, $until:desktop) {
                margin-right: 0px;
                margin-bottom: 4px;
            }
            &:last-child{
                margin-bottom: 0;

                @include mq($from: mobile, $until:desktop) {
                    margin-bottom: 0px;
                }
            }
        }
    }
}
