.page-newEmployee {
    .l-voiceContentsUser {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        margin-top: 40px;
        padding: 0 20px;

        // モバイル
        @include mq(mobile, desktop) {
            width: 100%;
            margin-top: 20px;
            padding: 0 10px;
        }

        &__Item {
            flex: 1 0 25%;
            position: relative;
            padding-right: 8px;
            padding-bottom: 8px;

            &:nth-child(4n) {
                padding-right: 0px;
            }

            // モバイル
            @include mq(mobile, desktop) {
                flex: 0 0 50%;
                padding-right: 3px;
                padding-bottom: 1px;

                &:nth-child(2n) {
                    padding-right: 0px;
                }
            }

            &-voice {
                @include font-size(13);
                color: rgba($GRY_LIGHT, 1);
                text-align: left;
                background-color: rgba($WHT, 1);
                padding: 10px 20px;
                position: relative;
                line-height: 1.8;

                // モバイル
                @include mq(mobile, desktop) {
                    @include font-size(10);
                    padding: 10px;
                }

                // モバイル・ランドスケープ
                @include mq($until: tablet, $and: '(orientation: landscape)') {
                    @include font-size(12);
                    padding: 10px 10px;
                }

                // タブレット
                @include mq(tablet, desktop) {
                    @include font-size(13);
                    padding: 10px 10px;
                }

                &:after {
                    top: 100%;
                    left: 50%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-color: rgba(255, 255, 255, 0);
                    border-top-color: rgba($YLW, 1);
                    border-width: 6px;
                    margin-left: -6px;
                }
            }
            &-user {
                color: rgba($GRY_LIGHT, 1);
                text-align: center;
                background-color: rgba($WHT, 1);
                border-top: 1px solid rgba($GRY_LIGHTEST, 1);
                padding: 10px 0;
                @include font-size(12);

                // モバイル
                @include mq(mobile, desktop) {
                    @include font-size(10);
                    padding: 10px 0;
                }

                // モバイル・ランドスケープ
                @include mq($until: tablet, $and: '(orientation: landscape)') {
                    @include font-size(11);
                    padding: 10px 0;
                }

                // タブレット
                @include mq(tablet, desktop) {
                    @include font-size(12);
                    padding: 10px 0;
                }

                i {
                    &:before {
                        margin-right: 2px;
                    }
                }
            }

            text-align: center;
            margin-bottom: 2px;
        }
    }

}
