.page-function {
    .l-featureArticle {
        width: 100%;
        border-bottom: 1px solid rgba($GRY_LIGHTEST, 1);
        position: relative;

        @include mq(mobile, desktop) {
            border-bottom: none;
            padding: 32px 0;
        }

        &::before {
            content: "";
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /*フレーム下部画像*/
            background-size: 100% 100%;

        }

        &:nth-child(odd) {
            background: url(../img/functionFutreInnrShadow_1.svg) no-repeat top 0 right 0;

            .l-featureArticle__Innr-photo {
                order: 1;
            }

            .l-featureArticle__Innr-text {
                order: 2;
            }

            @include mq(mobile, desktop) {
                background: none;
            }
        }

        &:nth-child(even) {
            background: url(../img/functionFutreInnrShadow_2.svg) no-repeat top 0 left 0;

            .l-featureArticle__Innr-photo {
                order: 2;
            }

            .l-featureArticle__Innr-text {
                order: 1;
            }

            @include mq(mobile, desktop) {
                background: rgba($GRY_LIGHTEST, 1) no-repeat top 0 right 0;
            }
        }

        &__Innr {
            width: 960px;
            height: 400px;
            position: relative;
            z-index: 3;
            margin: 0 auto;
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include mq(mobile, desktop) {
                display: block;
                width: 100%;
                min-height: auto;
                padding: 0 20px;
            }

            &-photo {

                @include mq(mobile, desktop) {
                    width: 100%;
                    text-align: center;
                }

            }

            &-wrap {}

            &-text {
                height: 100%;
                height: 400px;
                text-align: center;
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                align-items: center;

                @include mq(mobile, desktop) {
                    display: block;
                    width: 100%;
                    min-height: auto;
                    margin-top: 16px;
                }

                h4 {
                    @extend %blurbox;
                    border-radius: 50%;
                    width: width("iconService_04A.png", 2);
                    height: height("iconService_04A.png", 2);
                    margin: 0 auto;

                    @include mq(mobile, desktop) {
                        width: width("iconService_04A.png", 4);
                        height: height("iconService_04A.png", 4);
                    }

                    img {
                        width: auto;
                        height: height("iconService_04A.png", 2);

                        @include mq(mobile, desktop) {
                            width: width("iconService_04A.png", 4);
                            height: height("iconService_04A.png", 4);
                        }
                    }
                }

                h3 {
                    @extend .f-en_b;
                    @include font-size(24);
                    color: rgba($GRN, 1);
                    line-height: 1.5;
                    margin: 16px 0 8px;

                    @include mq(mobile, desktop) {
                        @include font-size(18);
                        margin-top: 8px;
                    }
                }

                .textBody {
                    width: 480px;
                    @extend .f-en_b;
                    @include font-size(16);
                    color: rgba($GRY_DARKER, 1);
                    line-height: 1.8;

                    @include mq(mobile, desktop) {
                        width: 100%;
                        @include font-size(12);
                        line-height: 1.6;
                    }

                    i{
                        color: rgba($RED, 1);
                        margin-right: 4px;
                    }

                    span{
                        @extend .f-en_m;
                        @include font-size(13);
                        color: rgba($GRY_DARKER, 1);
                        line-height: 1.1;

                        @include mq(mobile, desktop) {
                            @include font-size(10);
                        }

                    }
                }

            }
        }

        &.is-user {

            &.is-no1 {
                .l-featureArticle__Innr {
                    align-items: flex-end;
                }

                .l-featureArticle__Innr-photo {
                    img {
                        width: auto;
                        height: height("featureArticlephoto_1.png", 2);

                        @include mq(mobile, desktop) {
                            width: auto;
                            height: height("featureArticlephoto_1.png", 4);
                        }
                    }
                }
            }

            &.is-no2 {
                .l-featureArticle__Innr {
                    align-items: flex-end;
                }

                .l-featureArticle__Innr-photo {
                    img {
                        width: auto;
                        height: height("featureArticlephoto_2.png", 2);

                        @include mq(mobile, desktop) {
                            width: auto;
                            height: height("featureArticlephoto_1.png", 4);
                        }
                    }
                }
            }

            &.is-no3 {
                .l-featureArticle__Innr-photo {
                    img {
                        width: auto;
                        height: height("featureArticlephoto_3.png", 2);

                        @include mq(mobile, desktop) {
                            width: auto;
                            height: height("featureArticlephoto_1.png", 4);
                        }
                    }
                }
            }

            &.is-no4 {
                .l-featureArticle__Innr {
                    align-items: flex-end;
                }

                .l-featureArticle__Innr-photo {
                    img {
                        width: auto;
                        height: height("featureArticlephoto_4.png", 2);

                        @include mq(mobile, desktop) {
                            width: auto;
                            height: height("featureArticlephoto_1.png", 4);
                        }
                    }
                }
            }

            &.is-no5 {
                .l-featureArticle__Innr {
                    align-items: flex-end;
                }

                .l-featureArticle__Innr-photo {
                    img {
                        width: auto;
                        height: height("featureArticlephoto_5.png", 2);

                        @include mq(mobile, desktop) {
                            width: auto;
                            height: height("featureArticlephoto_1.png", 4);
                        }
                    }
                }
            }

            &.is-no6 {
                .l-featureArticle__Innr-photo {
                    img {
                        width: auto;
                        height: height("featureArticlephoto_6.png", 2);

                        @include mq(mobile, desktop) {
                            width: auto;
                            height: height("featureArticlephoto_1.png", 4);
                        }
                    }
                }
            }
        }

        &.is-jinji {
            &.is-no1 {
                .l-featureArticle__Innr-photo {
                    img {
                        width: auto;
                        height: height("featureArticlephotoM_1.png", 2);

                        @include mq(mobile, desktop) {
                            width: auto;
                            height: height("featureArticlephoto_1.png", 4);
                        }
                    }
                }
            }

            &.is-no2 {
                .l-featureArticle__Innr-photo {
                    img {
                        width: auto;
                        height: height("featureArticlephotoM_2.png", 2);

                        @include mq(mobile, desktop) {
                            width: auto;
                            height: height("featureArticlephotoM_2.png", 4);
                        }
                    }
                }
            }

            &.is-no3 {
                .l-featureArticle__Innr-photo {
                    img {
                        width: auto;
                        height: height("featureArticlephotoM_3.png", 2);

                        @include mq(mobile, desktop) {
                            width: auto;
                            height: height("featureArticlephotoM_3.png", 4);
                        }
                    }
                }
            }

            &.is-no4 {
                .l-featureArticle__Innr-photo {
                    img {
                        width: auto;
                        height: height("featureArticlephotoM_4.png", 2);

                        @include mq(mobile, desktop) {
                            width: auto;
                            height: height("featureArticlephotoM_4.png", 4);
                        }
                    }
                }
            }

            &.is-no5 {
                .l-featureArticle__Innr-photo {
                    img {
                        width: auto;
                        height: height("featureArticlephotoM_5.png", 2);

                        @include mq(mobile, desktop) {
                            width: auto;
                            height: height("featureArticlephotoM_5.png", 4);
                        }
                    }
                }
            }

            &.is-no6 {
                .l-featureArticle__Innr-photo {
                    img {
                        width: auto;
                        height: height("featureArticlephotoM_6.png", 2);

                        @include mq(mobile, desktop) {
                            width: auto;
                            height: height("featureArticlephotoM_6.png", 4);
                        }
                    }
                }
            }

            &.is-no7 {
                .l-featureArticle__Innr-photo {
                    img {
                        width: auto;
                        height: height("featureArticlephotoM_7.png", 2);

                        @include mq(mobile, desktop) {
                            width: auto;
                            height: height("featureArticlephotoM_7.png", 4);
                        }
                    }
                }
            }
        }
    }
}
