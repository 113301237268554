.page-newEmployee {

    .l-about {
        padding: 80px 0;
        text-align: center;

        // モバイル
        @include mq(mobile, desktop) {
            padding: 40px 0;
        }

        &__Innr {}

        &__Heading {
            &-sub {
                img {
                    height: 32px;

                    // モバイル
                    @include mq(mobile, desktop) {
                        height: 14px;
                    }

                    // モバイル・ランドスケープ
                    @include mq($until: tablet, $and: '(orientation: landscape)') {
                        height: 16px;
                    }

                    // タブレット
                    @include mq(tablet, desktop) {
                        height: 20px;
                    }
                }
            }

            &-lead {
                margin-top: 20px;

                // モバイル
                @include mq(mobile, desktop) {
                    margin-top: 12px;
                }

                // モバイル・ランドスケープ
                @include mq($until: tablet, $and: '(orientation: landscape)') {
                    margin-top: 12px;
                }

                // タブレット
                @include mq(tablet, desktop) {
                    margin-top: 15px;
                }

                img {
                    height: 60px;

                    // モバイル
                    @include mq(mobile, desktop) {
                        height: 24px;
                    }

                    // モバイル・ランドスケープ
                    @include mq($until: tablet, $and: '(orientation: landscape)') {
                        height: 36px;
                    }

                    // タブレット
                    @include mq(tablet, desktop) {
                        height: 48px;
                    }
                }
            }

            &-desc {
                margin-top: 20px;
                margin-bottom: 40px;
                color: rgba($GRY, 1);
                @include font-size(16);
                line-height: 2;
                font-weight: bold;
                text-align: center;

                // モバイル
                @include mq(mobile, desktop) {
                    margin-top: 15px;
                    margin-bottom: 30px;
                    @include font-size(12);
                    line-height: 1.6;
                }

                // モバイル・ランドスケープ
                @include mq($until: tablet, $and: '(orientation: landscape)') {}

                // タブレット
                @include mq(tablet, desktop) {
                    @include font-size(14);
                }
            }
        }

        &__Body {
            img {
                width: 909px;
                height: auto;

                // モバイル
                @include mq(mobile, desktop) {
                    width: 300px;
                    height: auto;
                }

                // モバイル・ランドスケープ
                @include mq($until: tablet, $and: '(orientation: landscape)') {
                    width: 400px;
                    height: auto;
                }

                // タブレット
                @include mq(tablet, desktop) {
                    width: 740px;
                    height: auto;
                }
            }
        }
    }

}
