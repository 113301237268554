    .l-step {
        padding: 80px 20px 0;
        position: relative;

        // モバイル
        @include mq(mobile, desktop) {
            padding: 56px 10px 20px;

            &:before {
                // モバイル
                @include mq(mobile, desktop) {
                    position: absolute;
                    top: 0;
                    right: auto;
                    left: calc(50% - 3px);

                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-color: rgba(0, 0, 0, 0);
                    border-top-color: $GRY_LIGHTEST;
                    border-width: 16px;
                    margin-left: -16px;
                }
            }
        }

        &__Innr {}
    }
