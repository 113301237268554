.page-newEmployee {
    .l-feature {
        padding: 80px 20px 20px;
        margin: 0 20px 20px;
        position: relative;

        // モバイル
        @include mq(mobile, desktop) {
            padding: 48px 10px 10px;
            margin: 0 0 10px;
            border-top: 1px solid rgba($GRY_LIGHTER, 1);

            &:before {
                position: absolute;
                top: -1px;
                left: calc(50% - 20px);
                content: "";
                width: 41px;
                height: 16px;
                background: url("../img/icon-bottom.png") no-repeat center top / 41px 15px;
            }

        }


        &__Innr {
            align-items: center;
            justify-content: center;
            padding: 0;
        }
    }
}
