@charset "utf-8";
html,
body {
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
}

/*MONO*/

$WHT: #FFF;
$GRY_LIGHTEST: #FBF8F5;
$GRY_LIGHTER: #E1DEDC;
$GRY_LIGHT: #787878;
$GRY: #4A4A4A;
$GRY_DARKER: #212121;
$GRY_DARKEST: #121212;
$NVY: #11141d;
$BLK: #000000;

/*
COLOR CHIESTA GREEN
*/
$GRN_Chiesta: #2aa738;
$GRN: #188A74;
$GRN_LIGHTEST: #EDF9F2;

/*
COLOR
*/
$ORG: #ffa500;
$RED: #f95759;
$BGE: #f4f4f4;
$YLW: #eac100;
$BLU: #1b96d5;
$GRN_LLL: #f7fafb;
$GRN_LL: #F3F9F8;
$GRN_L: #C1E4DF;
$GRN_D: #C5D5E8;

/*
COLOR WS
*/
$WS_A: #7ebf41;
$WS_B: #7bbd26;
$WS_C: #58b530;
$WS_D: #15922f;
$WS_E: #1ba035;
$WS_F: #1bac4a;
$WS_G: #10661e;

/*
GENRE
*/
$GNR-MNR: #1b96d5;
$GNR-HOW: #ffa500;
$GNR-SPK: #eac100;
$GNR-OJT: #1bac4a;
$GNR-WRT: #db6aa5;
$GNR-MNT: #7ebf41;
$GNR-SVY: #da463a;
$GNR-1st: #7ebf41;
$GNR-CST: #00427a;
$GNR-JTT: #14ae67;

$HOV: $RED;


a.is-tLink {
    color: rgba($BLK, 1);
    text-decoration: underline;
    letter-spacing: 3px;
    &:hover {
        color: rgba($GRY, 1);
        text-decoration: none;
    }
}

::-moz-selection {
    color: $WS_F;
    background: $YLW;
}

::selection {
    color: $WS_F;
    background: $YLW;
}




br.sp{
  @include mq(mobile,desktop) {
    display: block;
  }
  // モバイル・ランドスケープ
  @include mq($until: tablet, $and: '(orientation: landscape)'){
    display: none;
  }
  // タブレット
  @include mq(tablet,desktop) {
    display: none;
  }
  // タブレット
  @include mq(desktop) {
    display: none;
  }
}
br.spl{
  @include mq(mobile,desktop) {
    display: none;
  }
  // モバイル・ランドスケープ
  @include mq($until: tablet, $and: '(orientation: landscape)'){
    display: block;
  }
  // タブレット
  @include mq(tablet,desktop) {
    display: none;
  }
  // タブレット
  @include mq(desktop) {
    display: none;
  }
}
br.tb{
  @include mq(mobile,desktop) {
    display: none;
  }
  // モバイル・ランドスケープ
  @include mq($until: tablet, $and: '(orientation: landscape)'){
    display: none;
  }
  // タブレット
  @include mq(tablet,desktop) {
    display: block;
  }
  // タブレット
  @include mq(desktop) {
    display: none;
  }
}
br.pc{
  @include mq(mobile,desktop) {
    display: none;
  }
  // モバイル・ランドスケープ
  @include mq($until: tablet, $and: '(orientation: landscape)'){
    display: none;
  }
  // タブレット
  @include mq(tablet,desktop) {
    display: none;
  }
  // タブレット
  @include mq(desktop) {
    display: block;
  }
}





%font-16_grn {
    @include font-size(16);
    line-height: 2;
    color: rgba($GRN, 1);
}

%font-16_wht {
    @include font-size(16);
    line-height: 2;
    color: rgba($WHT, 1);
}

%font-16_blk {
    @include font-size(16);
    line-height: 2;
    color: rgba($BLK, 1);
}

%font-14_grn {
    @include font-size(14);
    line-height: 1.8;
    color: rgba($GRN, 1);
}

%font-14_wht {
    @include font-size(14);
    line-height: 1.8;
    color: rgba($WHT, 1);
}

%font-14_blk {
    @include font-size(14);
    line-height: 1.8;
    color: rgba($BLK, 1);
}

%font-12_grn {
    @include font-size(11);
    line-height: 1.6;
    color: rgba($GRN, 1);
}

%font-12f_grn {
    @include font-size(11);
    line-height: 1;
    color: rgba($GRN, 1);
}

%font-12_wht {
    @include font-size(11);
    line-height: 1.6;
    color: rgba($WHT, 1);
}

%font-12_blk {
    @include font-size(11);
    line-height: 1.6;
    color: rgba($BLK, 1);
}



.font-16_grn {
    @include font-size(16);
    line-height: 2;
    color: rgba($GRN, 1);
}

.font-16_wht {
    @include font-size(16);
    line-height: 2;
    color: rgba($WHT, 1);
}

.font-16_blk {
    @include font-size(16);
    line-height: 2;
    color: rgba($BLK, 1);
}

.font-14_grn {
    @include font-size(14);
    line-height: 1.8;
    color: rgba($GRN, 1);
}

.font-14_wht {
    @include font-size(14);
    line-height: 1.8;
    color: rgba($WHT, 1);
}

.font-14_blk {
    @include font-size(14);
    line-height: 1.8;
    color: rgba($BLK, 1);
}

.font-12_grn {
    @include font-size(11);
    line-height: 1.6;
    color: rgba($GRN, 1);
}

.font-12f_grn {
    @include font-size(11);
    line-height: 1;
    color: rgba($GRN, 1);
}

.font-12_wht {
    @include font-size(11);
    line-height: 1.6;
    color: rgba($WHT, 1);
}

.font-12_blk {
    @include font-size(11);
    line-height: 1.6;
    color: rgba($BLK, 1);
}
