.page-top {
    .l-quality {
        margin-top: 5px;
        padding: 0;

        &__Innr {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: stretch;

            // モバイル
            @include mq(mobile, desktop) {
                flex-wrap: wrap;
            }

            // モバイル・ランドスケープ
            @include mq($until: tablet, $and: '(orientation: landscape)') {
                flex-wrap: wrap;
            }

            // タブレット
            @include mq(tablet, desktop) {
                flex-wrap: no-wrap;
            }
        }
    }
}
